// CreateAIPost.js
import React, { useState } from 'react';
import { 
  Wand2, 
  Loader2, 
  CheckCircle2, 
  AlertCircle,
  ArrowLeft,
  Copy,
  Sparkles,
  PanelLeft,
  PanelRight,
  ChevronLeft,
  ChevronRight
} from 'lucide-react';
import { Alert, AlertDescription } from './Alert';
import PostPreview from './PostPreview';
import { useUser } from '../UserContext';
import { useUsageLimits } from '../useUsageLimits';
import GeneratedPostsCarousel from './GeneratedPostsCarousel'

const toneOptions = [
  { value: 'professional', label: 'Professional', icon: '💼' },
  { value: 'casual', label: 'Casual', icon: '😊' },
  { value: 'humorous', label: 'Humorous', icon: '😄' },
  { value: 'authoritative', label: 'Authoritative', icon: '👔' },
  { value: 'inspirational', label: 'Inspirational', icon: '✨' }
];

const templateOptions = [
  {
    value: 'experiment',
    label: 'X-Day Challenge',
    description: 'Share results and insights from a time-bound experiment'
  },
  { 
    value: 'success', 
    label: 'Success Story',
    description: 'Share a compelling success story with proven results'
  },
  { 
    value: 'contrarian', 
    label: 'Contrarian Take',
    description: 'Challenge common assumptions with unique insights'
  },
  { 
    value: 'framework', 
    label: 'Framework/Process',
    description: 'Present a step-by-step system for achieving results'
  },
  { 
    value: 'mistakes', 
    label: 'Common Mistakes',
    description: 'Highlight key mistakes and their solutions'
  },
  { 
    value: 'simpleTip', 
    label: 'Quick Win',
    description: 'Share a powerful yet simple actionable tip'
  },
  { 
    value: 'insight', 
    label: 'Industry Insight',
    description: 'Provide valuable industry analysis and trends'
  },
  {
    value: 'educational',
    label: 'Educational',
    description: 'Create comprehensive yet simple explanations of complex topics'
  },
  {
    value: 'breakingNews',
    label: 'Breaking News',
    description: 'Share and analyze latest industry developments'
  }
];

const styleOptions = [
  { value: 'storytelling', label: 'Storytelling', icon: '📖' },
  { value: 'bullets', label: 'Bullet Points', icon: '•' },
  { value: 'paragraph', label: 'Paragraphs', icon: '¶' },
  { value: 'numbered', label: 'Numbered List', icon: '1.' }
];

const lengthOptions = [
  { value: 'short', label: '100-150 words', description: 'Quick, impactful read' },
  { value: 'medium', label: '200-300 words', description: 'Detailed but concise' }
//   { value: 'long', label: '400-600 words', description: 'In-depth content' }
];

const CreateAIPost = ({ onClose, onSelectPost }) => {
  const { user } = useUser();
  const { canUseAiGeneration, incrementUsage } = useUsageLimits();
  const [showSidebar, setShowSidebar] = useState(true);
  
  const [formData, setFormData] = useState({
    topic: '',
    context: '',
    tone: 'professional',
    template: 'success',
    style: 'storytelling',
    length: 'medium'
  });
  
  const [isGenerating, setIsGenerating] = useState(false);
  const [error, setError] = useState('');
  const [generatedPosts, setGeneratedPosts] = useState([]);
  const [selectedPost, setSelectedPost] = useState(null);
  const [selectedPostIndex, setSelectedPostIndex] = useState(null);

  const handleInputChange = ({ name, value }) => {
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleGenerate = async () => {
    if (!formData.topic) {
      setError('Please enter a topic');
      return;
    }

    if (!canUseAiGeneration()) {
      setError('You have reached your AI generation limit');
      return;
    }

    setIsGenerating(true);
    setError('');

    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/generate-post`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(formData)
      });

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.error || 'Failed to generate posts');
      }

      const data = await response.json();
      setGeneratedPosts(data.variations);
      await incrementUsage('aiGeneration');
      setShowSidebar(false); // Auto-hide sidebar after generation

    } catch (err) {
      console.error('Error generating posts:', err);
      setError(err.message || 'Failed to generate posts');
    } finally {
      setIsGenerating(false);
    }
  };

  const handleUsePost = () => {
    if (selectedPost) {
      onSelectPost(selectedPost);
    }
  };

  const handlePostSelect = (post, index) => {
    setSelectedPost(post.content);
    setSelectedPostIndex(index);
  };

    // New scroll handling for template options
    const scrollTemplates = (direction) => {
        const container = document.getElementById('template-container');
        if (container) {
          const scrollAmount = direction === 'left' ? -300 : 300;
          container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
        }
      };

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {
      console.error('Failed to copy text:', err);
    }
  };

  // Compact form input component
  const CompactField = ({ label, name, type = 'text', value, onChange, options = null, description = null }) => {
    const handleInputChange = (e) => {
      onChange({ 
        name: e.target.name, 
        value: e.target.value 
      });
    };
  
    return (
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700 mb-1">
          {label}
        </label>
        {type === 'textarea' ? (
          <textarea
            name={name}
            value={value}
            onChange={handleInputChange}
            rows={3}
            className="w-full p-3 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder={`Enter ${label.toLowerCase()}`}
          />
        ) : type === 'select' ? (
          <div className="grid grid-cols-2 gap-2">
            {options.map((option) => (
              <button
                key={option.value}
                onClick={() => onChange({ name, value: option.value })}
                className={`p-3 text-left rounded-lg border transition-colors ${
                  value === option.value
                    ? 'border-blue-500 bg-blue-50'
                    : 'border-gray-200 hover:border-gray-300'
                }`}
              >
                <div className="flex items-center">
                  {option.icon && <span className="mr-2">{option.icon}</span>}
                  <span className="font-medium">{option.label}</span>
                </div>
                {option.description && (
                  <p className="mt-1 text-xs text-gray-500">{option.description}</p>
                )}
              </button>
            ))}
          </div>
        ) : type === 'textarea' ? (
          <textarea
            name={name}
            value={value || ''}
            onChange={handleInputChange}
            rows={3}
            className="w-full p-3 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder={`Enter ${label.toLowerCase()}`}
          />
          ) : (
            <input
              type={type}
              name={name}
              value={value}
              onChange={handleInputChange}
              className="w-full p-3 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder={`Enter ${label.toLowerCase()}`}
            />
          )}
        </div>
      );
    };

  return (
    <div className="flex h-full bg-gray-50">
      {/* Collapsible Sidebar */}
      <div className={`${showSidebar ? 'w-96' : 'w-0'} transition-all duration-300 overflow-hidden border-r bg-white`}>
        <div className="p-4">
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center">
              <Wand2 className="w-5 h-5 mr-2 text-blue-600" />
              <h2 className="text-lg font-semibold">AI Post Generator</h2>
            </div>
            <button onClick={() => setShowSidebar(false)} className="text-gray-400 hover:text-gray-600">
              <PanelLeft className="w-5 h-5" />
            </button>
          </div>

          {error && (
            <Alert variant="destructive" className="mb-4">
              <AlertCircle className="h-4 w-4" />
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}

          <div className="space-y-4">
            <CompactField
              label="Topic"
              name="topic"
              value={formData.topic}
              onChange={handleInputChange}
            />

            <CompactField
              label="Additional Context"
              name="context"
              type="textarea"
              value={formData.context}
              onChange={handleInputChange}
            />

            <CompactField
              label="Template"
              name="template"
              type="select"
              value={formData.template}
              onChange={handleInputChange}
              options={templateOptions}
            />

            <CompactField
              label="Tone"
              name="tone"
              type="select"
              value={formData.tone}
              onChange={handleInputChange}
              options={toneOptions}
            />

            <CompactField
              label="Style"
              name="style"
              type="select"
              value={formData.style}
              onChange={handleInputChange}
              options={styleOptions}
            />

            <CompactField
              label="Length"
              name="length"
              type="select"
              value={formData.length}
              onChange={handleInputChange}
              options={lengthOptions}
            />

            <button
              onClick={handleGenerate}
              disabled={isGenerating || !canUseAiGeneration()}
              className="w-full mt-4 px-4 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50 flex items-center justify-center"
            >
              {isGenerating ? (
                <>
                  <Loader2 className="w-5 h-5 mr-2 animate-spin" />
                  Generating...
                </>
              ) : (
                <>
                  <Sparkles className="w-5 h-5 mr-2" />
                  Generate Posts
                </>
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Main Content Area */}
      <div className="flex-1 overflow-hidden">
        <div className="h-full flex flex-col">
          {/* Header */}
          <div className="p-4 border-b bg-white flex items-center justify-between">
            <div className="flex items-center">
              {!showSidebar && (
                <button 
                  onClick={() => setShowSidebar(true)}
                  className="mr-4 text-gray-400 hover:text-gray-600"
                >
                  <PanelRight className="w-5 h-5" />
                </button>
              )}
              <h2 className="text-lg font-semibold">Generated Posts</h2>
            </div>
            <button
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700"
            >
              <ArrowLeft className="w-5 h-5" />
            </button>
          </div>

          {/* Generated Content with Use Post Button */}
          <div className="flex-1 overflow-auto py-8 bg-gray-50">
            <div className="max-w-[800px] mx-auto flex flex-col">
              <GeneratedPostsCarousel
                posts={generatedPosts}
                onSelect={(post, index) => handlePostSelect(post, index)}
                selectedIndex={selectedPostIndex}
                userProfile={{
                  name: user?.name,
                  title: user?.title,
                  avatar: user?.socialAccounts?.linkedin?.picture
                }}
              />
              
              {/* Use Selected Post button immediately after carousel */}
              {selectedPost && (
                <div className="mt-6 px-4">
                  <button
                    onClick={handleUsePost}
                    className="w-full px-4 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 flex items-center justify-center"
                  >
                    <CheckCircle2 className="w-5 h-5 mr-2" />
                    Use Selected Post
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <style jsx>{`
        .hide-scrollbar {
          scrollbar-width: none;
          -ms-overflow-style: none;
        }
        .hide-scrollbar::-webkit-scrollbar {
          display: none;
        }
      `}</style>
    </div>
  );
};

export default CreateAIPost;