// In UserContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { userService } from './services/userService';

const UserContext = createContext();

export function UserProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);

  // Function to verify token is valid
  const verifyToken = async (token) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/verify-token`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      if (!response.ok) {
        throw new Error('Token verification failed');
      }
      
      const data = await response.json();
      console.log('Token verification successful:', data);
      return true;
    } catch (error) {
      console.error('Token verification error:', error);
      return false;
    }
  };

  // Function to fetch subscription status
  const fetchSubscriptionStatus = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/stripe/subscription-status`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        
        if (!response.ok) throw new Error('Failed to fetch subscription status');
        
        const data = await response.json();
        setSubscriptionStatus(data);
        
        // Update user object with subscription data
        setUser(prev => prev ? {
          ...prev,
          subscription: {
            ...prev.subscription,
            ...data
          }
        } : prev);
        
      } catch (error) {
        console.error('Error fetching subscription:', error);
      }
  };
  

  const fetchUserProfile = async () => {
    try {
      const storedToken = localStorage.getItem('token');
      console.log('Stored token:', storedToken ? 'Present' : 'Missing');

      if (storedToken) {
        // Verify token before using it
        const isValid = await verifyToken(storedToken);
        if (!isValid) {
          console.log('Stored token is invalid, clearing user data');
          localStorage.removeItem('token');
          setToken(null);
          setUser(null);
          setError('Session expired. Please login again.');
          return;
        }

        console.log('Fetching user profile with token');
        const userData = await userService.getUserProfile();
        console.log('User data received:', userData);
        setUser(userData);
        await fetchSubscriptionStatus();
      }
    } catch (err) {
      console.error('Error fetching user profile:', err);
      setError(err.message);
      
      if (err.message.includes('401')) {
        console.log('Unauthorized error, clearing token and user data');
        localStorage.removeItem('token');
        setToken(null);
        setUser(null);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const login = async (newToken, initialUserData) => {
    console.log('Login called with token:', newToken ? 'Present' : 'Missing');
    
    try {
      // Verify the new token
      const isValid = await verifyToken(newToken);
      if (!isValid) {
        throw new Error('Invalid token received during login');
      }
  
      localStorage.setItem('token', newToken);
      setToken(newToken);
      setUser(initialUserData);
      
      // Fetch complete user profile
      await fetchUserProfile();
      console.log('Login successful, user profile updated');
  
      // Replace the current URL with dashboard, clearing the history stack
      window.location.replace('/dashboard');
    } catch (error) {
      console.error('Error during login:', error);
      localStorage.removeItem('token');
      setToken(null);
      setUser(null);
      throw error;
    }
  };

  const logout = () => {
    console.log('Logout called, clearing user data');
    localStorage.removeItem('token');
    setToken(null);
    setUser(null);
  };

  // Helper function to check if user has available limit
  const hasAvailableLimit = (limitType) => {
      if (!user?.usageLimits?.[limitType]) return false;
      const { limit, used } = user.usageLimits[limitType];
      return limit === -1 || used < limit;
  };

  // Function to increment usage
  const incrementUsage = async (limitType) => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user/limits/increment`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ limitType })
        });
  
        if (!response.ok) throw new Error('Failed to increment usage');
  
        const updatedUser = await response.json();
        setUser(updatedUser);
        return true;
      } catch (error) {
        console.error('Error incrementing usage:', error);
        return false;
      }
  };
  

  const contextValue = {
    user,
    token,
    login,
    logout,
    loading,
    error,
    hasAvailableLimit,
    refreshProfile: fetchUserProfile,
    incrementUsage
  };

  return (
    <UserContext.Provider value={contextValue}>
      {children}
    </UserContext.Provider>
  );
}

export function useUser() {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
}