import React, { useState, useRef, useEffect } from 'react';
import { Camera, Smile, Hash, Link2, Video, FileText, AlertCircle } from 'lucide-react';
import { Alert, AlertDescription } from './Alert';

const PostToolbar = ({ content, onContentChange, onImageUpload, onVideoUpload, onDocumentUpload }) => {
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [showHashtags, setShowHashtags] = useState(false);
  const [uploadError, setUploadError] = useState('');
  const toolbarRef = useRef(null);
  const imageInputRef = useRef(null);
  const videoInputRef = useRef(null);
  const documentInputRef = useRef(null);
  const characterLimit = 3000;

  const MAX_DOC_SIZE = 100 * 1024 * 1024; // 100MB
  const SUPPORTED_DOC_TYPES = [
  'application/pdf',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ];

  // Extended emoji list with categories (keep existing categories)
  const emojiCategories = {
    "Smileys & People": ['😀', '😃', '😄', '😁', '😅', '😂', '🤣', '😊', '😇', '🙂', '😉', '😍', '🥰', '😘'],
    "Gestures": ['👍', '👎', '👏', '🙌', '🤝', '🤲', '🫶', '💪', '🤜', '🤛', '✌️', '🫰', '🤌', '👌'],
    "Hearts & Love": ['❤️', '🧡', '💛', '💚', '💙', '💜', '🤎', '🖤', '💖', '💗', '💓', '💝', '💕', '💞'],
    "Business": ['💼', '💻', '📱', '📊', '📈', '🎯', '⭐', '💡', '🚀', '📝', '✍️', '🤝', '💪', '🎉'],
    "Nature": ['🌟', '⭐', '✨', '💫', '☀️', '🌈', '🌸', '🌺', '🌼', '🌻', '🍀', '🌿', '🌱', '🌳']
  };

  // Extended hashtag suggestions with categories (keep existing categories)
  const hashtagCategories = {
    "Business": [
      'business', 'entrepreneur', 'startup', 'smallbusiness', 'marketing',
      'leadership', 'success', 'motivation', 'businesstips', 'entrepreneurlife'
    ],
    "Marketing": [
      'digitalmarketing', 'socialmedia', 'contentmarketing', 'branding',
      'marketingstrategy', 'growthhacking', 'sem', 'seo', 'emailmarketing', 'advertising'
    ],
    "Technology": [
      'tech', 'technology', 'innovation', 'ai', 'artificialintelligence',
      'blockchain', 'coding', 'programming', 'software', 'cybersecurity'
    ],
    "Professional": [
      'career', 'networking', 'professionaldevelopment', 'jobsearch',
      'resume', 'careeradvice', 'worklife', 'remotework', 'hiring', 'jobs'
    ],
    "Industry": [
      'fintech', 'ecommerce', 'saas', 'realestate', 'healthcare',
      'education', 'retail', 'consulting', 'manufacturing', 'logistics'
    ]
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (toolbarRef.current && !toolbarRef.current.contains(event.target)) {
        setShowEmojiPicker(false);
        setShowHashtags(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const insertTextAtCursor = (textToInsert) => {
    const textarea = document.querySelector('textarea');
    if (!textarea) return;

    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;
    const newContent = 
      content.substring(0, start) + 
      textToInsert + 
      content.substring(end);

    onContentChange(newContent);
    
    setTimeout(() => {
      textarea.selectionStart = textarea.selectionEnd = start + textToInsert.length;
      textarea.focus();
    }, 0);
  };

  const handleVideoUpload = (event) => {
    const file = event.target.files?.[0];
    if (file) {
      // Validate video constraints
      if (file.size > 500 * 1024 * 1024) { // 500MB
        alert('Video size must not exceed 500MB');
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        onVideoUpload(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDocumentUpload = (event) => {
    const file = event.target.files?.[0];
    if (!file) return;

    // Validate file type
    if (!SUPPORTED_DOC_TYPES.includes(file.type)) {
      setUploadError('Unsupported file type. Please upload PDF, PPT, PPTX, DOC, or DOCX files.');
      return;
    }

    // Validate file size
    if (file.size > MAX_DOC_SIZE) {
      setUploadError('File size must not exceed 100MB');
      return;
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      setUploadError('');
      onDocumentUpload(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files?.[0];
    if (file) {
      const fileType = file.type.startsWith('image/') ? 'image' : 'unknown';
      if (fileType === 'unknown') {
        alert('Please upload an image file');
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        onImageUpload(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="flex items-center justify-between border-t border-b py-3" ref={toolbarRef}>
      <div className="flex items-center space-x-4">
        {/* Image Upload Button */}
        <div className="relative">
          <input
            type="file"
            ref={imageInputRef}
            className="hidden"
            onChange={handleFileUpload}
            accept="image/*"
          />
          <button 
            onClick={() => imageInputRef.current?.click()}
            className="p-2 hover:bg-gray-100 rounded-lg"
          >
            <Camera className="w-5 h-5 text-gray-600" />
          </button>
        </div>

        {/* Video Upload Button */}
        <div className="relative">
            <input
              type="file"
              ref={videoInputRef}
              className="hidden"
              onChange={handleVideoUpload}
              accept="video/mp4"
            />
            <button 
              onClick={() => videoInputRef.current?.click()}
              className="p-2 hover:bg-gray-100 rounded-lg"
              title="Upload video"
            >
              <Video className="w-5 h-5 text-gray-600" />
            </button>
          </div>

          {/* New Document Upload Button */}
          <div className="relative">
          <input
            type="file"
            ref={documentInputRef}
            className="hidden"
            onChange={handleDocumentUpload}
            accept=".pdf,.ppt,.pptx,.doc,.docx,application/pdf,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          />
          <button 
            onClick={() => documentInputRef.current?.click()}
            className="p-2 hover:bg-gray-100 rounded-lg"
            title="Upload document (PDF, PPT, DOC)"
          >
            <FileText className="w-5 h-5 text-gray-600" />
          </button>
          </div>

        {/* Emoji Picker */}
        <div className="relative">
          <button 
            onClick={() => setShowEmojiPicker(!showEmojiPicker)}
            className="p-2 hover:bg-gray-100 rounded-lg"
          >
            <Smile className="w-5 h-5 text-gray-600" />
          </button>
          {showEmojiPicker && (
            <div className="absolute bottom-full mb-2 bg-white shadow-lg rounded-lg p-4 w-[320px] max-h-[400px] overflow-y-auto z-50">
              {Object.entries(emojiCategories).map(([category, emojis]) => (
                <div key={category} className="mb-4">
                  <h3 className="text-sm font-medium text-gray-700 mb-2">{category}</h3>
                  <div className="grid grid-cols-7 gap-2">
                    {emojis.map((emoji, index) => (
                      <button
                        key={index}
                        onClick={() => {
                          insertTextAtCursor(emoji);
                          setShowEmojiPicker(false);
                        }}
                        className="p-2 hover:bg-gray-100 rounded text-xl transition-colors"
                      >
                        {emoji}
                      </button>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        
        {/* Hashtag Picker */}
        <div className="relative">
          <button 
            onClick={() => setShowHashtags(!showHashtags)}
            className="p-2 hover:bg-gray-100 rounded-lg"
          >
            <Hash className="w-5 h-5 text-gray-600" />
          </button>
          {showHashtags && (
            <div className="absolute bottom-full mb-2 bg-white shadow-lg rounded-lg p-4 w-[320px] max-h-[400px] overflow-y-auto z-50">
              {Object.entries(hashtagCategories).map(([category, hashtags]) => (
                <div key={category} className="mb-4">
                  <h3 className="text-sm font-medium text-gray-700 mb-2">{category}</h3>
                  <div className="grid grid-cols-2 gap-2">
                    {hashtags.map((tag, index) => (
                      <button
                        key={index}
                        onClick={() => {
                          insertTextAtCursor(` #${tag} `);
                          setShowHashtags(false);
                        }}
                        className="text-left px-3 py-2 hover:bg-gray-100 rounded text-sm transition-colors truncate"
                      >
                        #{tag}
                      </button>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        
        <button 
          onClick={() => insertTextAtCursor(' https://example.com ')}
          className="p-2 hover:bg-gray-100 rounded-lg"
        >
          <Link2 className="w-5 h-5 text-gray-600" />
        </button>
      </div>

      {uploadError && (
        <div className="absolute top-full left-0 right-0 mt-2">
          <Alert variant="destructive">
            <AlertCircle className="h-4 w-4" />
            <AlertDescription>{uploadError}</AlertDescription>
          </Alert>
        </div>
      )}
      
      <div className="text-sm text-gray-500">
        {content.length}/{characterLimit}
      </div>
    </div>
  );
};

export default PostToolbar;