import React, { useState, useEffect } from 'react';
import PostInput from './PostInput';
import PostToolbar from './PostToolbar';
import PostActions from './PostActions';
import PostPreview from './PostPreview';
import ScheduleDialog from './ScheduleDialog';
import AccountSelector from './AccountSelector';
import { Alert, AlertDescription } from './Alert';
import { useUser } from '../UserContext';
import { 
  PLATFORMS, 
  PLATFORM_LABELS, 
  VALIDATION, 
  POST_STATUS 
} from '../Constants';
import { useUsageLimits } from '../useUsageLimits';
import InspirationModal from './InspirationModal';

// Add media validation constants to match backend constraints
const MEDIA_CONSTRAINTS = {
  IMAGE: {
    maxSize: 5 * 1024 * 1024, // 5MB
    formats: ['image/jpeg', 'image/png', 'image/gif'],
    dimensions: {
      min: { width: 400, height: 400 },
      max: { width: 4096, height: 4096 }
    }
  },
  VIDEO: {
    maxSize: 200 * 1024 * 1024, // 200MB
    formats: ['video/mp4'],
    duration: { max: 1800 } // 30 minutes in seconds
  },
  PDF: {
    maxSize: 100 * 1024 * 1024, // 100MB
    formats: ['application/pdf']
  }
};

const MAX_DOC_SIZE = 100 * 1024 * 1024; // 100MB
const SUPPORTED_DOC_TYPES = [
  'application/pdf',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
];

const CreatePost = ({ 
  onClose, 
  connectedAccounts = [],
  initialContent = '',
  initialImage = null,
  initialComment = '',
  initialSelectedAccounts = [],
  mode = 'manual', // 'manual' or 'ai'
  className = ''
}) => {
  const { user } = useUser();
  const [content, setContent] = useState(initialContent);
  const [selectedAccounts, setSelectedAccounts] = useState(initialSelectedAccounts);
  const [selectedImage, setSelectedImage] = useState(initialImage);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [firstComment, setFirstComment] = useState(initialComment);
  const [error, setError] = useState('');
  const [showScheduleDialog, setShowScheduleDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showAIGenerator, setShowAIGenerator] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [showInspirationModal, setShowInspirationModal] = useState(false);
  const { 
    canSchedulePost,
    canPublishPost, 
    canUseAiGeneration,
    formatLimitDisplay, 
    getNextResetDate,
    incrementUsage,
    limits 
  } = useUsageLimits();

    // Add effect to handle AI mode initialization
    // useEffect(() => {
    //   if (mode === 'ai') {
    //     setShowAIGenerator(true);
    //   }
    // }, [mode]);

    // Add useEffect to update content when initialContent changes
    useEffect(() => {
      if (initialContent) {
        setContent(initialContent);
      }
    }, [initialContent]);


    // Updated media validation function
    const validateMedia = (file) => {
      return new Promise((resolve, reject) => {
        if (!file) {
          reject(new Error('No file provided'));
          return;
        }
  
        // Handle both File objects and base64 strings
        if (file instanceof File) {
          // Handle File object
          const mediaType = getMediaTypeFromMimeType(file.type);
          validateFileObject(file, mediaType, resolve, reject);
        } else if (typeof file === 'string' && file.startsWith('data:')) {
          // Handle base64 string
          const mimeType = file.split(';')[0].split(':')[1];
          const mediaType = getMediaTypeFromMimeType(mimeType);
          validateBase64String(file, mediaType, resolve, reject);
        } else {
          reject(new Error('Invalid media format'));
        }
      });
    };
  
    // Helper function to get media type from MIME type
    const getMediaTypeFromMimeType = (mimeType) => {
      if (!mimeType) return null;
      
      if (mimeType.startsWith('image/')) return 'IMAGE';
      if (mimeType.startsWith('video/')) return 'VIDEO';
      if (mimeType === 'application/pdf') return 'PDF';
      return null;
    };
  
    // Validate File object
    const validateFileObject = (file, mediaType, resolve, reject) => {
      const constraints = MEDIA_CONSTRAINTS[mediaType];
  
      if (!constraints) {
        reject(new Error('Unsupported media type'));
        return;
      }
  
      // Check file size
      if (file.size > constraints.maxSize) {
        reject(new Error(`File size exceeds ${constraints.maxSize / (1024 * 1024)}MB limit`));
        return;
      }
  
      // Check format
      if (!constraints.formats.includes(file.type)) {
        reject(new Error(`Unsupported format. Allowed formats: ${constraints.formats.join(', ')}`));
        return;
      }
      resolve(true);
    };
  
    // Validate base64 string
    const validateBase64String = (base64String, mediaType, resolve, reject) => {
      const constraints = MEDIA_CONSTRAINTS[mediaType];
  
      if (!constraints) {
        reject(new Error('Unsupported media type'));
        return;
      }
  
      // Check format
      const mimeType = base64String.split(';')[0].split(':')[1];
      if (!constraints.formats.includes(mimeType)) {
        reject(new Error(`Unsupported format. Allowed formats: ${constraints.formats.join(', ')}`));
        return;
      }
  
      // Calculate base64 size
      const base64Length = base64String.split(',')[1].length;
      const fileSize = (base64Length * 3) / 4; // Approximate size in bytes
  
      if (fileSize > constraints.maxSize) {
        reject(new Error(`File size exceeds ${constraints.maxSize / (1024 * 1024)}MB limit`));
        return;
      }
      resolve(true);
    };

// Video validation functions
const validateVideoFile = (file, resolve, reject) => {
  if (file.size > MEDIA_CONSTRAINTS.VIDEO.maxSize) {
    reject(new Error('Video size must not exceed 200MB'));
    return;
  }

  if (!MEDIA_CONSTRAINTS.VIDEO.formats.includes(file.type)) {
    reject(new Error('Please upload an MP4 video'));
    return;
  }

  // Additional video duration check could be added here
  resolve(true);
};

const validateVideoBase64 = (base64String, resolve, reject) => {
  const mimeType = base64String.split(';')[0].split(':')[1];
  if (!MEDIA_CONSTRAINTS.VIDEO.formats.includes(mimeType)) {
    reject(new Error('Please upload an MP4 video'));
    return;
  }

  const base64Data = base64String.split(',')[1];
  const sizeInBytes = Math.ceil((base64Data.length * 3) / 4);
  if (sizeInBytes > MEDIA_CONSTRAINTS.VIDEO.maxSize) {
    reject(new Error('Video size must not exceed 200MB'));
    return;
  }

  resolve(true);
};

const validateVideo = (file) => {
  return new Promise((resolve, reject) => {
    if (!file) {
      reject(new Error('No video file provided'));
      return;
    }

    // Handle both File objects and base64 strings
    if (file instanceof File) {
      validateVideoFile(file, resolve, reject);
    } else if (typeof file === 'string' && file.startsWith('data:video/')) {
      validateVideoBase64(file, resolve, reject);
    } else {
      reject(new Error('Invalid video format'));
    }
  });
};

const handleInspirationPost = (post) => {
  // Extract the content from the LinkedIn post
  const inspirationContent = post.content || post.text;
  
  // Update the content using the existing onContentChange handler
  // onContentChange(inspirationContent);
  
  // Close the modal
  setShowInspirationModal(false);
};

  // Add new function to handle AI improvement
  const handleAIImprovement = async () => {
    if (!content.trim()) {
      setError('Please write some content first');
      return;
    }

    if (!canUseAiGeneration()) {
      setError('You have reached your AI generation limit');
      return;
    }

    setIsLoading(true);
    setError('');

    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/improve-post`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          content: content.trim()
        })
      });

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.error || 'Failed to improve content');
      }

      const data = await response.json();
      setContent(data.improvedContent);
      await incrementUsage('aiGeneration');
      
    } catch (err) {
      console.error('Error improving content:', err);
      setError(err.message || 'Failed to improve content');
    } finally {
      setIsLoading(false);
      setShowAIGenerator(false);
    }
  };


const handleDocumentUpload = async (file) => {
  if (!file) {
    setSelectedDocument(null);
    setError('');
    return;
  }

  try {
    // For files from input element
    if (file instanceof File) {
      if (!SUPPORTED_DOC_TYPES.includes(file.type)) {
        throw new Error('Unsupported file type. Please upload PDF, PPT, PPTX, DOC, or DOCX files.');
      }

      if (file.size > MAX_DOC_SIZE) {
        throw new Error('File size must not exceed 100MB');
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedDocument({
          data: reader.result,
          name: file.name,
          type: file.type,
          size: file.size
        });
      };
      reader.readAsDataURL(file);
    }
    // For base64 strings
    else if (typeof file === 'string' && file.startsWith('data:')) {
      const mimeType = file.split(';')[0].split(':')[1];
      if (!SUPPORTED_DOC_TYPES.includes(mimeType)) {
        throw new Error('Unsupported file type');
      }

      const base64Size = Math.ceil((file.length * 3) / 4);
      if (base64Size > MAX_DOC_SIZE) {
        throw new Error('File size must not exceed 100MB');
      }

      setSelectedDocument({
        data: file,
        type: mimeType,
        name: 'document'
      });
    }

    setError('');
  } catch (err) {
    console.error('Document validation error:', err);
    setError(err.message);
    setSelectedDocument(null);
  }
};

// Add video handling functions
const handleVideoUpload = async (file) => {
  if (!file) {
    setSelectedVideo(null);
    setError('');
    return;
  }

  try {
    // Check if it's a video file
    if (!file.startsWith('data:video/')) {
      throw new Error('Invalid video format. Please upload an MP4 video.');
    }

    const base64Data = file.split(',')[1];
    const sizeInBytes = Math.ceil((base64Data.length * 3) / 4);
    
    // Check file size (500MB limit)
    if (sizeInBytes > MEDIA_CONSTRAINTS.VIDEO.maxSize) {
      throw new Error('Video size must not exceed 200MB');
    }

    setSelectedVideo(file);
    setError('');
  } catch (err) {
    console.error('Video validation error:', err);
    setError(err.message);
    setSelectedVideo(null);
  }
};

  // Helper function to prepare post content
  const preparePostContent = () => {

    let mediaContent = null;
    let mediaType = null;
    let additionalMetadata = {};

    if (selectedVideo) {
      mediaContent = selectedVideo;
      mediaType = 'VIDEO';
    } else if (selectedImage) {
      mediaContent = selectedImage;
      mediaType = 'IMAGE';
    } else if (selectedDocument) {
      mediaContent = selectedDocument.data;
      mediaType = 'DOCUMENT';
      additionalMetadata = {
        documentName: selectedDocument.name,
        documentType: selectedDocument.type,
        documentSize: selectedDocument.size
      };
    }

    return {
      text: content.trim(),
      media: mediaContent,
      mediaType: mediaType,
      firstComment: firstComment.trim(),
      ...additionalMetadata
    };
  };

  // Helper function to make API request
  const makePostRequest = async (endpoint, payload) => {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('You must be logged in to create posts');
    }

    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/posts`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(payload)
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || errorData.details || 'Failed to create post');
    }

    return response.json();
  };

  const handlePublishNow = async () => {
    if (!validatePost()) return;
    
    if (!canPublishPost()) {
      setError('You have reached your published posts limit for this period');
      return;
    }

    setIsPublishing(true);
    setError('');

    try {
      const postContent = preparePostContent();
      const payload = {
        content: postContent,
        platforms: selectedAccounts,
        publishNow: true
      };

      const response = await makePostRequest('/api/posts', payload);
      
      // If post was published successfully, increment the usage counter
      await incrementUsage('publishedPosts');
      
      onClose();
    } catch (err) {
      console.error('Error publishing post:', err);
      setError(err.message || 'Failed to publish post');
    } finally {
      setIsPublishing(false);
    }
  };


  const handleSaveAsDraft = async () => {
    if (!validatePost()) return;

    setIsLoading(true);
    setError('');

    try {
      const postContent = preparePostContent();
      const payload = {
        content: postContent,
        platforms: selectedAccounts,
        isDraft: true
      };

      await makePostRequest('/api/posts', payload);
      onClose();
    } catch (err) {
      console.error('Error saving draft:', err);
      setError(err.message || 'Failed to save draft. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSchedulePost = async (scheduledDate, scheduledTime) => {
    if (!validatePost()) return;

    if (!canSchedulePost()) {
      setError('You have reached your scheduled posts limit for this period');
      return;
    }

    const scheduledDateTime = new Date(`${scheduledDate}T${scheduledTime}`);
    if (scheduledDateTime <= new Date()) {
      setError('Scheduled time must be in the future');
      return;
    }

    setIsLoading(true);
    setError('');

    try {
      const postContent = preparePostContent();
      const payload = {
        content: postContent,
        platforms: selectedAccounts,
        scheduledAt: scheduledDateTime.toISOString(),
        isDraft: false
      };

      await makePostRequest('/api/posts', payload);
      // Increment usage counter if post was scheduled successfully
      await incrementUsage('scheduledPosts');
      
      onClose();
    } catch (err) {
      console.error('Error scheduling post:', err);
      setError(err.message || 'Failed to schedule post. Please try again.');
    } finally {
      setIsLoading(false);
      setShowScheduleDialog(false);
    }
  };

      // Updated handleImageUpload function
      const handleImageUpload = async (file) => {
        if (!file) {
          setSelectedImage(null);
          setError('');
          return;
        }
      
        try {
          // Determine file type from data URL
          const fileType = file.startsWith('data:image/') ? 'IMAGE' :
                          file.startsWith('data:video/') ? 'VIDEO' :
                          file.startsWith('data:application/pdf') ? 'PDF' : null;
      
          if (!fileType) {
            throw new Error('Unsupported file type');
          }
      
          const constraints = MEDIA_CONSTRAINTS[fileType];
          
          // Extract mime type and base64 data
          const [header, base64Data] = file.split(',');
          const mimeType = header.split(':')[1].split(';')[0];
      
          // Check format
          if (!constraints.formats.includes(mimeType)) {
            throw new Error(`Unsupported format. Allowed formats: ${constraints.formats.join(', ')}`);
          }
      
          // Check size
          const sizeInBytes = Math.ceil((base64Data.length * 3) / 4);
          if (sizeInBytes > constraints.maxSize) {
            throw new Error(`File size exceeds ${constraints.maxSize / (1024 * 1024)}MB limit`);
          }
      
          setSelectedImage(file);
          setError('');
        } catch (err) {
          console.error('Media validation error:', err);
          setError(err.message);
          setSelectedImage(null);
        }
      };

  const validatePost = () => {
    if (!content.trim()) { // && content.mediaType === null
      setError('Post text content cannot be empty');
      return false;
    }

    if (selectedAccounts.length === 0) {
      setError('Please select at least one platform');
      return false;
    }

    // Check if LinkedIn is selected but not connected
    if (selectedAccounts.includes(PLATFORMS.LINKEDIN) && 
        (!user?.socialAccounts?.linkedin?.isConnected)) {
      setError('Please connect your LinkedIn account first');
      return false;
    }

    return true;
  };

  const handlePostValidation = () => {
    if (!validatePost()) return;
    setShowScheduleDialog(true)
  }
  
  return (
    <div className={`flex gap-4 ${className}`}>
      <div className="flex-1">
        <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="flex items-center justify-between mb-6">
            <h2 className="text-xl font-semibold text-gray-900">
            {mode === 'ai' ? 'Create Post with AI' : 'Create Post'}
            </h2>
          </div>

          {error && (
            <Alert variant="destructive" className="mb-6">
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}

          <AccountSelector
            accounts={connectedAccounts.map(account => ({
              id: account.toLowerCase(), // Ensure lowercase platform IDs
              label: PLATFORM_LABELS[account.toLowerCase()], // Use proper labels
              isConnected: user?.socialAccounts?.[account.toLowerCase()]?.isConnected || false
            }))}
          selectedAccounts={selectedAccounts}
          onAccountToggle={(account) => {
            setSelectedAccounts(prev => 
              prev.includes(account)
                ? prev.filter(a => a !== account)
                : [...prev, account]
            );
            setError('');
          }}
          onShowAIGenerator={handleAIImprovement}
          content={content}
        />

<PostInput
  content={content}
  onContentChange={setContent}
  selectedImage={selectedImage}
  selectedVideo={selectedVideo}
  selectedDocument={selectedDocument}
  onDocumentChange={setSelectedDocument}
  onImageChange={handleImageUpload}
  onVideoChange={handleVideoUpload}
  firstComment={firstComment}
  onFirstCommentChange={setFirstComment}
  showAIGenerator={showAIGenerator}
  onShowAIGenerator={setShowAIGenerator}
/>

<PostToolbar
  content={content}
  onContentChange={setContent}
  onImageUpload={handleImageUpload}
  onVideoUpload={handleVideoUpload}
  onDocumentUpload={handleDocumentUpload}
/>

          <PostActions
            isLoading={isLoading}
            isPublishing={isPublishing}
            onSaveDraft={handleSaveAsDraft}
            onSchedule={handlePostValidation}
            isDraftDisabled={!content.trim() || selectedAccounts.length === 0}
            isScheduleDisabled={!content.trim() || selectedAccounts.length === 0}
            onPublishNow={handlePublishNow}
          />
        </div>
      </div>

      <PostPreview
        content={content}
        selectedImage={selectedImage}
        userProfile={{
          name: user?.name || user?.email,
          title: user?.company || 'Professional',
          avatar: user?.socialAccounts?.linkedin?.picture // Add user avatar handling if needed
        }}
      />

      {showScheduleDialog && (
        <ScheduleDialog
          onClose={() => setShowScheduleDialog(false)}
          onSchedule={handleSchedulePost}
          isLoading={isLoading}
          platforms={selectedAccounts}
        />
      )}

<InspirationModal
  isOpen={showInspirationModal}
  onClose={() => setShowInspirationModal(false)}
  onSelectPost={handleInspirationPost}
/>
    </div>
  );
};

export default CreatePost;