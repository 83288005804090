import React, { useState, useRef, useEffect } from 'react';
import { 
  ChevronLeft, 
  ChevronRight, 
  Copy, 
  Sparkles,
  ThumbsUp,
  MessageSquare, 
  Repeat2, 
  Send,
  Globe2,
  MoreHorizontal
} from 'lucide-react';

const GeneratedPostsCarousel = ({ posts, onSelect, selectedIndex, userProfile = {} }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const containerRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);

  // Initialize default profile with fallbacks
  const {
    name = 'Your Name',
    title = 'Your Professional Title',
    avatar = null,
  } = userProfile;

  useEffect(() => {
    if (posts.length > 0) {
      const timer = setTimeout(() => {
        setIsLoading(false);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [posts]);

  const copyToClipboard = async (e, text) => {
    e.stopPropagation(); // Prevent card selection when copying
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {
      console.error('Failed to copy text:', err);
    }
  };

  const scrollToPost = (index) => {
    if (containerRef.current) {
      const post = containerRef.current.children[index];
      if (post) {
        post.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
      }
    }
    setCurrentPage(index);
  };

  if (posts.length === 0) {
    return (
      <div className="text-center py-12">
        <Sparkles className="w-12 h-12 text-gray-400 mx-auto mb-4" />
        <h3 className="text-lg font-medium text-gray-900 mb-2">
          No posts generated yet
        </h3>
        <p className="text-gray-500">
          Fill out the form and click generate to create AI-powered post variations
        </p>
      </div>
    );
  }

  const LinkedInPostCard = ({ post, index, isSelected }) => (
    <div 
      onClick={() => onSelect(post, index)}
      className={`w-[400px] mx-auto transition-all duration-200 ${
        isSelected ? 'ring-2 ring-blue-500 ring-inset shadow-lg scale-[1.02]' : ''
      }`}
    >
      <div className="bg-white rounded-lg shadow-sm">
        <div className="p-4">
          {/* Header Section */}
          <div className="flex items-start justify-between">
            <div className="flex items-start flex-1">
              <div className="h-12 w-12 rounded-full bg-gray-200 flex-shrink-0 flex items-center justify-center text-gray-500 text-lg font-medium border border-gray-300">
                {avatar ? (
                  <img 
                    src={avatar} 
                    alt={name} 
                    className="h-full w-full rounded-full object-cover"
                  />
                ) : (
                  name.charAt(0)
                )}
              </div>
              <div className="ml-2 flex-1">
                <div className="flex items-center justify-between">
                  <div>
                    <h4 className="font-semibold text-[14px] text-gray-900 hover:text-blue-600 hover:underline cursor-pointer">
                      {name}
                    </h4>
                    <p className="text-[12px] text-gray-500 leading-tight">
                      {title}
                    </p>
                    <div className="flex items-center text-[12px] text-gray-500 mt-0.5">
                      <span className="mr-1">AI Generated</span>
                      <span className="mx-1">·</span>
                      <Globe2 className="w-3 h-3" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex space-x-2">
              <button
                onClick={(e) => copyToClipboard(e, post.content)}
                className="p-2 text-gray-400 hover:text-gray-600 hover:bg-gray-100 rounded-full"
              >
                <Copy className="w-4 h-4" />
              </button>
              <button className="p-2 hover:bg-gray-100 rounded-full -mr-2">
                <MoreHorizontal className="w-5 h-5 text-gray-600" />
              </button>
            </div>
          </div>

          {/* Content Section */}
          <div className="mt-3">
            {isLoading ? (
              <div className="space-y-3">
                <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                <div className="h-4 bg-gray-200 rounded"></div>
                <div className="h-4 bg-gray-200 rounded w-5/6"></div>
              </div>
            ) : (
              <p className="text-[14px] text-gray-900 whitespace-pre-wrap">
                {post.content}
              </p>
            )}
          </div>

          {/* Engagement Stats */}
          <div className="mt-3 pt-1">
            <div className="flex items-center justify-between text-[12px] text-gray-500 pb-2 border-b">
              <div className="flex items-center">
                <div className="flex -space-x-1">
                  <div className="w-4 h-4 rounded-full bg-blue-500 flex items-center justify-center">
                    <ThumbsUp className="w-2 h-2 text-white" />
                  </div>
                  <div className="w-4 h-4 rounded-full bg-red-500 flex items-center justify-center">
                    <span className="text-white text-[8px]">♥</span>
                  </div>
                </div>
                <span className="ml-2 hover:text-blue-600 hover:underline cursor-pointer">
                  Version {index + 1} of {posts.length}
                </span>
              </div>
              <div className="flex items-center space-x-4">
                <span className="hover:text-blue-600 hover:underline cursor-pointer">
                  0 comments
                </span>
                <span className="hover:text-blue-600 hover:underline cursor-pointer">
                  0 reposts
                </span>
              </div>
            </div>
          </div>

          {/* Action Buttons */}
          <div className="mt-1 flex items-center justify-between">
            <button className="flex-1 flex items-center justify-center py-3 hover:bg-gray-100 rounded-lg group">
              <ThumbsUp className="w-4 h-4 text-gray-600 group-hover:text-blue-600" />
              <span className="ml-2 text-[13px] font-medium text-gray-600 group-hover:text-blue-600">Like</span>
            </button>
            <button className="flex-1 flex items-center justify-center py-3 hover:bg-gray-100 rounded-lg group">
              <MessageSquare className="w-4 h-4 text-gray-600 group-hover:text-blue-600" />
              <span className="ml-2 text-[13px] font-medium text-gray-600 group-hover:text-blue-600">Comment</span>
            </button>
            <button className="flex-1 flex items-center justify-center py-3 hover:bg-gray-100 rounded-lg group">
              <Repeat2 className="w-4 h-4 text-gray-600 group-hover:text-blue-600" />
              <span className="ml-2 text-[13px] font-medium text-gray-600 group-hover:text-blue-600">Repost</span>
            </button>
            <button className="flex-1 flex items-center justify-center py-3 hover:bg-gray-100 rounded-lg group">
              <Send className="w-4 h-4 text-gray-600 group-hover:text-blue-600" />
              <span className="ml-2 text-[13px] font-medium text-gray-600 group-hover:text-blue-600">Send</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="relative">
      {/* Navigation Buttons */}
      {currentPage > 0 && (
        <button
          onClick={() => scrollToPost(currentPage - 1)}
          className="absolute left-4 top-1/2 -translate-y-1/2 z-10 bg-white rounded-full p-2 shadow-lg hover:bg-gray-50"
        >
          <ChevronLeft className="w-6 h-6 text-gray-600" />
        </button>
      )}
      {currentPage < posts.length - 1 && (
        <button
          onClick={() => scrollToPost(currentPage + 1)}
          className="absolute right-4 top-1/2 -translate-y-1/2 z-10 bg-white rounded-full p-2 shadow-lg hover:bg-gray-50"
        >
          <ChevronRight className="w-6 h-6 text-gray-600" />
        </button>
      )}

      {/* Carousel Container */}
      <div 
        ref={containerRef}
        className="flex overflow-x-scroll snap-x snap-mandatory hide-scrollbar"
      >
        {posts.map((post, index) => (
          <div
            key={index}
            className="min-w-full snap-center flex items-center justify-center p-4"
          >
            <LinkedInPostCard 
              post={post} 
              index={index} 
              isSelected={selectedIndex === index}
            />
          </div>
        ))}
      </div>

      {/* Dots Navigation */}
      <div className="flex justify-center space-x-2 mt-4">
        {posts.map((_, index) => (
          <button
            key={index}
            onClick={() => scrollToPost(index)}
            className={`w-2 h-2 rounded-full transition-colors ${
              currentPage === index ? 'bg-blue-600' : 'bg-gray-300'
            }`}
          />
        ))}
      </div>

      {/* Custom scrollbar styling */}
      <style jsx>{`
        .hide-scrollbar {
          scrollbar-width: none;
          -ms-overflow-style: none;
        }
        .hide-scrollbar::-webkit-scrollbar {
          display: none;
        }
      `}</style>
    </div>
  );
};

export default GeneratedPostsCarousel;