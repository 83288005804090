import React, { useState, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { format, startOfMonth, endOfMonth, startOfWeek, endOfWeek, eachDayOfInterval, isSameMonth, isSameDay, addMonths, subMonths, parseISO, isValid } from 'date-fns';
import { ChevronLeft, ChevronRight, Calendar as CalendarIcon, LayoutGrid, Menu, Clock, ExternalLink, Linkedin, Image as ImageIcon } from 'lucide-react';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import { Alert, AlertDescription } from './components/Alert';


const ExpandedCard = ({ post, position, onClose }) => {
  const expandedCardRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (expandedCardRef.current && !expandedCardRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onClose]);

  const formatPostDate = (dateString) => {
    try {
      const date = parseISO(dateString);
      return isValid(date) ? format(date, 'MMM d, yyyy h:mm a') : '';
    } catch (error) {
      return '';
    }
  };

  const getStatusColor = (status) => {
    const colors = {
      scheduled: 'bg-blue-100 text-blue-800',
      draft: 'bg-gray-100 text-gray-800',
      posted: 'bg-green-100 text-green-800'
    };
    return colors[status] || colors.draft;
  };

  const openLinkedInPost = () => {
    if (post.linkedinPostUrl) {
      window.open(post.linkedinPostUrl, '_blank');
    }
  };

  return createPortal(
    <div 
      ref={expandedCardRef}
      className="fixed z-50 bg-white rounded-lg shadow-xl border border-gray-200"
      style={{
        top: `${position.top}px`,
        left: `${position.left}px`,
        width: '320px',
        maxHeight: '400px',
        overflow: 'auto'
      }}
    >
      <div className="p-4">
        {/* Header with LinkedIn View Button */}
        {post.status === 'posted' && post.linkedinPostUrl && (
          <div className="flex justify-end mb-3">
            <button
              onClick={openLinkedInPost}
              className="flex items-center px-3 py-1.5 text-sm font-medium text-[#0A66C2] hover:bg-blue-50 rounded-full transition-colors"
            >
              <ExternalLink className="w-4 h-4 mr-2" />
              View on LinkedIn
            </button>
          </div>
        )}

        {/* Post Content */}
        <div className="space-y-4">
          <p className="text-sm text-gray-900 whitespace-pre-wrap">
            {post.content}
          </p>

          {/* Media Preview */}
          {post.content.media && (
            <div className="relative group rounded-lg overflow-hidden bg-gray-100 h-32">
              <img
                src={post.content.media}
                alt="Post media"
                className="w-full h-full object-cover"
              />
            </div>
          )}
        </div>

        {/* Footer with Status and Date */}
        <div className="border-t mt-4 pt-3">
          <div className="flex items-center justify-between">
          <div className="flex items-center text-sm text-gray-500">
              <Clock className="w-4 h-4 mr-2" />
              {post.status === 'posted' ? 'Posted' : 'Scheduled for'}: {' '}
              {formatPostDate(post.status === 'posted' ? post.publishedAt : post.scheduledAt)}
            </div>
            <span className={`text-xs px-2 py-1 rounded-full ${getStatusColor(post.status)}`}>
              {post.status}
            </span>
          </div>
        </div>
      </div>
    </div>,
    document.body
  );
};


const PostCard = ({ post }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [expandedPosition, setExpandedPosition] = useState({ top: 0, left: 0 });
  const cardRef = useRef(null);

  const handleMouseEnter = () => {
    if (cardRef.current) {
      const rect = cardRef.current.getBoundingClientRect();
      const scrollY = window.scrollY;
      
      const viewportHeight = window.innerHeight;
      let top = rect.top + scrollY;
      let left = rect.left + rect.width + 10;

      if (top + 400 > viewportHeight + scrollY) {
        top = viewportHeight + scrollY - 400 - 20;
      }

      if (left + 320 > window.innerWidth) {
        left = rect.left - 320 - 10;
      }

      setExpandedPosition({ top, left });
    }
    setIsHovered(true);
  };

  const getStatusColor = (status) => {
    const colors = {
      scheduled: 'bg-blue-100 text-blue-800',
      draft: 'bg-gray-100 text-gray-800',
      posted: 'bg-green-100 text-green-800'
    };
    return colors[status] || colors.draft;
  };

  const openLinkedInPost = () => {
    if (post.linkedinPostUrl) {
      window.open(post.linkedinPostUrl, '_blank');
    }
  };

  return (
    <div
      ref={cardRef}
      className="p-3 mb-1 rounded-md bg-white shadow-sm border border-gray-100 hover:shadow-md transition-all cursor-pointer"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* Header with content and view button */}
      <div className="flex items-start justify-between mb-3">
        <p className="text-sm text-gray-900 line-clamp-2 flex-1 mr-3">
          {post.content}
        </p>
        {post.linkedinPostUrl && (
          <button
            onClick={openLinkedInPost}
            className="flex-shrink-0 text-xs text-blue-600 hover:text-blue-700"
          >
            <ExternalLink className="w-4 h-4" />
          </button>
        )}
      </div>

      {/* Footer with status and platform */}
      <div className="flex items-center justify-between mt-2">
        <span className={`text-xs px-2 py-1 rounded-full ${getStatusColor(post.status)}`}>
          {post.status}
        </span>
        <div className="flex items-center space-x-1">
          {post.platforms.map(platform => (
            <span key={platform} className="flex items-center">
              {platform === 'linkedin' && (
                <Linkedin className="w-4 h-4 text-[#0A66C2]" />
              )}
            </span>
          ))}
        </div>
      </div>

      {/* Expanded hover card */}
      {isHovered && (
        <ExpandedCard
          post={post}
          position={expandedPosition}
          onClose={() => setIsHovered(false)}
        />
      )}
    </div>
  );
};


const Calendar = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [view, setView] = useState('month'); // 'month' or 'week'
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchPosts = async (start, end) => {
    setLoading(true);
    setError(null);
    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('No auth token found');

      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/posts`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) throw new Error('Failed to fetch posts');

      const data = await response.json();
      setPosts(data.posts || []);
    } catch (err) {
      console.error('Error fetching posts:', err);
      setError('Failed to load posts. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const start = startOfMonth(currentDate);
    const end = endOfMonth(currentDate);
    fetchPosts(start, end);
  }, [currentDate]);

  const getPostsForDay = (day) => {
    return posts.filter(post => {
      try {
        const postDate = parseISO(post.scheduledAt || post.createdAt);
        return isValid(postDate) && isSameDay(postDate, day);
      } catch (error) {
        console.error('Error parsing post date:', error);
        return false;
      }
    });
  };

  const renderMonthView = () => {
    const monthStart = startOfMonth(currentDate);
    const monthEnd = endOfMonth(currentDate);
    const calendarStart = startOfWeek(monthStart);
    const calendarEnd = endOfWeek(monthEnd);
    const days = eachDayOfInterval({ start: calendarStart, end: calendarEnd });

    return (
      <div className="grid grid-cols-7 gap-1">
        {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
          <div key={day} className="p-2 text-center text-sm font-medium text-gray-600">
            {day}
          </div>
        ))}
        {days.map((day, idx) => {
          const dayPosts = getPostsForDay(day);
          const isCurrentMonth = isSameMonth(day, currentDate);

          return (
            <div
              key={idx}
              className={`min-h-[120px] p-2 border rounded-lg ${
                isCurrentMonth ? 'bg-white' : 'bg-gray-50'
              }`}
            >
              <div className={`text-sm font-medium mb-1 ${
                isCurrentMonth ? 'text-gray-900' : 'text-gray-400'
              }`}>
                {format(day, 'd')}
              </div>
              <div className="space-y-1 overflow-y-auto max-h-[100px]">
                {dayPosts.map(post => (
                  <PostCard key={post._id} post={post} />
                ))}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderWeekView = () => {
    const weekStart = startOfWeek(currentDate);
    const weekEnd = endOfWeek(currentDate);
    const days = eachDayOfInterval({ start: weekStart, end: weekEnd });

    return (
      <div className="grid grid-cols-7 gap-2">
        {days.map((day, idx) => (
          <div key={idx} className="min-h-[600px]">
            <div className="text-center p-2 bg-gray-50 rounded-t-lg">
              <div className="font-medium text-gray-900">
                {format(day, 'EEE')}
              </div>
              <div className="text-sm text-gray-500">
                {format(day, 'MMM d')}
              </div>
            </div>
            <div className="border rounded-b-lg bg-white p-2 min-h-[calc(100%-4rem)] space-y-2">
              {getPostsForDay(day).map(post => (
                <PostCard key={post._id} post={post} />
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      <div className="flex flex-1">
        <Sidebar />
        <main className="flex-1 bg-gray-100 p-6">
          <div className="max-w-7xl mx-auto">
            {/* Calendar Header */}
            <div className="flex items-center justify-between mb-6">
              <div className="flex items-center space-x-4">
                <h1 className="text-2xl font-semibold text-gray-900">Calendar</h1>
                <div className="flex items-center bg-white rounded-lg shadow-sm p-1">
                  <button
                    onClick={() => setView('month')}
                    className={`px-3 py-1.5 rounded ${
                      view === 'month'
                        ? 'bg-blue-50 text-blue-600'
                        : 'text-gray-600 hover:bg-gray-50'
                    }`}
                  >
                    <LayoutGrid className="w-4 h-4" />
                  </button>
                  <button
                    onClick={() => setView('week')}
                    className={`px-3 py-1.5 rounded ${
                      view === 'week'
                        ? 'bg-blue-50 text-blue-600'
                        : 'text-gray-600 hover:bg-gray-50'
                    }`}
                  >
                    <Menu className="w-4 h-4" />
                  </button>
                </div>
              </div>

              <div className="flex items-center space-x-4">
                <div className="flex items-center bg-white rounded-lg shadow-sm">
                  <button
                    onClick={() => setCurrentDate(subMonths(currentDate, 1))}
                    className="p-2 hover:bg-gray-50 rounded-l-lg"
                  >
                    <ChevronLeft className="w-5 h-5 text-gray-600" />
                  </button>
                  <div className="px-4 py-2 font-medium">
                    {format(currentDate, 'MMMM yyyy')}
                  </div>
                  <button
                    onClick={() => setCurrentDate(addMonths(currentDate, 1))}
                    className="p-2 hover:bg-gray-50 rounded-r-lg"
                  >
                    <ChevronRight className="w-5 h-5 text-gray-600" />
                  </button>
                </div>
                <button
                  onClick={() => setCurrentDate(new Date())}
                  className="px-4 py-2 bg-white text-gray-600 rounded-lg shadow-sm hover:bg-gray-50"
                >
                  Today
                </button>
              </div>
            </div>

            {/* Error Alert */}
            {error && (
              <Alert variant="destructive" className="mb-4">
                <AlertDescription>{error}</AlertDescription>
              </Alert>
            )}

            {/* Loading State */}
            {loading ? (
              <div className="flex justify-center items-center h-[600px]">
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
              </div>
            ) : (
              // Calendar Grid
              <div className="bg-white rounded-lg shadow-sm p-4">
                {view === 'month' ? renderMonthView() : renderWeekView()}
              </div>
            )}
          </div>
        </main>
      </div>
    </div>
  );
};

export default Calendar;