import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Alert, AlertDescription } from './components/Alert';
import { useUser } from './UserContext';
import { AlertCircle, Loader2, Linkedin, Instagram, Camera, Share2, Youtube,
  Facebook, Twitter } from 'lucide-react';

const AuthUI = () => {
  const [error, setError] = useState('');
  const [isProcessingAuth, setIsProcessingAuth] = useState(false);
  const navigate = useNavigate();
  const { login, user } = useUser();
  const location = useLocation();

  // Existing auth processing logic
  useEffect(() => {
    if (user) {
      window.location.replace('/dashboard');
      return;
    }

    if (isProcessingAuth) {
      return;
    }

    const handleAuthentication = async () => {
      try {
        setIsProcessingAuth(true);
        const params = new URLSearchParams(location.search);
        const token = params.get('token');
        
        if (token) {
          localStorage.setItem('token', token);
          await login(token);
          window.location.replace('/dashboard');
        }
      } catch (error) {
        console.error('Authentication error:', error);
        setError('Authentication failed. Please try again.');
      } finally {
        setIsProcessingAuth(false);
      }
    };
  
    handleAuthentication();
  }, [location, navigate, login, user, isProcessingAuth]);

  const handleGoogleAuth = () => {
    window.location.href = `${process.env.REACT_APP_API_URL}/auth/google`;
  };

  // Platform animation component
  const PlatformIcon = ({ icon: Icon, delay, position }) => (
    <div
      className={`absolute ${position} transform`}
      style={{
        animation: `
          float 6s ease-in-out infinite ${delay}s,
          pulse 2s ease-in-out infinite ${delay}s,
          moveToCenter 8s ease-in-out infinite ${delay}s
        `
      }}
    >
      <div className="bg-white p-3 rounded-full shadow-lg">
        <Icon className="w-6 h-6" />
      </div>
    </div>
  );

  // Central hub component
  const CentralHub = () => (
    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
      <div className="relative">
        <div className="w-16 h-16 bg-blue-600 rounded-full flex items-center justify-center shadow-xl">
          <Share2 className="w-8 h-8 text-white" />
        </div>
        <div className="absolute inset-0 bg-blue-400/20 rounded-full"
          style={{ animation: 'ripple 2s linear infinite' }} />
        <div className="absolute inset-0 bg-blue-400/10 rounded-full"
          style={{ animation: 'ripple 2s linear infinite 1s' }} />
      </div>
    </div>
  );

  const platformIcons = [
    { icon: Linkedin, delay: 0, position: 'top-1/4 left-1/4', color: 'text-[#0A66C2]' },
    { icon: Instagram, delay: 1, position: 'top-1/4 right-1/4', color: 'text-[#E4405F]' },
    { icon: Twitter, delay: 2, position: 'bottom-1/4 left-1/4', color: 'text-black' },
    //{ icon: Youtube, delay: 3, position: 'bottom-1/4 right-1/4', color: 'text-black' },
    { icon: Facebook, delay: 4, position: 'bottom-1/4 right-1/4', color: 'text-black' }
    // Add more platform icons as needed
  ];

  return (
    <div className="min-h-screen flex">
      {/* Left animated section */}
      <div className="hidden lg:flex lg:w-1/2 bg-gradient-to-br from-blue-50 to-indigo-50 relative overflow-hidden">
        <div className="absolute inset-0 z-0">
          {/* Platform icons orbiting around central hub */}
          {platformIcons.map((platform, index) => (
            <PlatformIcon 
              key={index}
              icon={platform.icon}
              delay={platform.delay}
              position={platform.position}
            />
          ))}
          
          {/* Central management hub */}
          {/* <CentralHub /> */}

          {/* Connection lines (optional) */}
          <div className="absolute inset-0 z-0">
            <svg className="w-full h-full" style={{ animation: 'rotate 20s linear infinite' }}>
              <circle 
                cx="50%" 
                cy="50%" 
                r="30%" 
                fill="none" 
                stroke="rgba(59, 130, 246, 0.1)" 
                strokeWidth="2"
                strokeDasharray="5,5"
              />
            </svg>
          </div>
        </div>
        
        <div className="relative z-10 flex flex-col items-center justify-center w-full p-12">
          <h1 className="text-4xl font-bold text-gray-900 mb-6">
            All Platforms, One Tool
          </h1>
          <p className="text-xl text-gray-600 text-center max-w-md">
            Grow and Manage all your social media accounts from a single, powerful dashboard
          </p>
        </div>
      </div>

      {/* Right sign-in section */}
      <div className="w-full lg:w-1/2 flex flex-col justify-center px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full mx-auto">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold text-gray-900 mb-2">
              Get Started
            </h2>
            <p className="text-gray-600">
              Sign in to grow and manage your social media presence
            </p>
          </div>

          {error && (
            <Alert variant="destructive" className="mb-6">
              <AlertCircle className="h-4 w-4" />
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}

          <button
            onClick={handleGoogleAuth}
            disabled={isProcessingAuth}
            className="w-full flex items-center justify-center px-4 py-3 border border-transparent rounded-lg shadow-sm text-base font-medium text-white bg-blue-600 hover:bg-blue-700 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors relative"
          >
            {isProcessingAuth ? (
              <Loader2 className="w-5 h-5 animate-spin" />
            ) : (
              <>
                <svg className="w-5 h-5 mr-3" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M12.24 10.285V14.4h6.806c-.275 1.765-2.056 5.174-6.806 5.174-4.095 0-7.439-3.389-7.439-7.574s3.345-7.574 7.439-7.574c2.33 0 3.891.989 4.785 1.849l3.254-3.138C18.189 1.186 15.479 0 12.24 0c-6.635 0-12 5.365-12 12s5.365 12 12 12c6.926 0 11.52-4.869 11.52-11.726 0-.788-.085-1.39-.189-1.989H12.24z"
                  />
                </svg>
                Continue with Google
              </>
            )}
          </button>

          <div className="mt-6 text-center text-sm text-gray-500">
            By signing in, you agree to our{' '}
            <a href="/privacy" className="text-blue-600 hover:text-blue-500">
              Terms of Service
            </a>{' '}
            and{' '}
            <a href="/privacy" className="text-blue-600 hover:text-blue-500">
              Privacy Policy
            </a>
          </div>
        </div>
      </div>

      {/* Add animation keyframes */}
      <style jsx="true">{`
        @keyframes float {
          0%, 100% { transform: translateY(0); }
          50% { transform: translateY(-20px); }
        }
        
        @keyframes pulse {
          0%, 100% { transform: scale(1); }
          50% { transform: scale(1.1); }
        }
        
        @keyframes moveToCenter {
          0%, 100% { transform: translate(0, 0); }
          50% { transform: translate(20px, 20px); }
        }
        
        @keyframes rotate {
          from { transform: rotate(0deg); }
          to { transform: rotate(360deg); }
        }
        
        @keyframes ripple {
          0% { transform: scale(1); opacity: 1; }
          100% { transform: scale(2); opacity: 0; }
        }
      `}</style>
    </div>
  );
};

export default AuthUI;