import React from 'react';
import { Wand2, Edit2, X } from 'lucide-react';

const CreatePostModal = ({ isOpen, onClose, onCreateWithAI, onCreateManually }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg max-w-md w-full mx-4 overflow-hidden">
        <div className="flex justify-between items-center p-4 border-b">
          <h2 className="text-lg font-semibold">Create New Post</h2>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-full transition-colors"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        <div className="p-6 space-y-4">
          <button
            onClick={onCreateWithAI}
            className="w-full flex items-center p-4 bg-gradient-to-r from-blue-50 to-indigo-50 hover:from-blue-100 hover:to-indigo-100 rounded-lg border border-blue-200 transition-colors group"
          >
            <div className="w-10 h-10 bg-blue-600 rounded-full flex items-center justify-center flex-shrink-0">
              <Wand2 className="w-5 h-5 text-white" />
            </div>
            <div className="ml-4 text-left flex-1">
              <h3 className="font-semibold text-gray-900">Create with AI</h3>
              <p className="text-sm text-gray-600">Let AI help you generate engaging content</p>
            </div>
            <div className="w-6 h-6 text-blue-600 opacity-0 group-hover:opacity-100 transition-opacity">
              →
            </div>
          </button>

          <button
            onClick={onCreateManually}
            className="w-full flex items-center p-4 bg-gradient-to-r from-gray-50 to-gray-100 hover:from-gray-100 hover:to-gray-200 rounded-lg border border-gray-200 transition-colors group"
          >
            <div className="w-10 h-10 bg-gray-900 rounded-full flex items-center justify-center flex-shrink-0">
              <Edit2 className="w-5 h-5 text-white" />
            </div>
            <div className="ml-4 text-left flex-1">
              <h3 className="font-semibold text-gray-900">Create Myself</h3>
              <p className="text-sm text-gray-600">Write and customize your own content</p>
            </div>
            <div className="w-6 h-6 text-gray-600 opacity-0 group-hover:opacity-100 transition-opacity">
              →
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreatePostModal;