import React, { useState, useEffect } from 'react';
import { Calendar, Mail, ArrowRight, Clock, CheckCircle, Users, AlertCircle } from 'lucide-react';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import { waitlistService } from './services/waitlistService';
import { Alert, AlertDescription } from './components/Alert';

const ComingSoonContent = ({ 
  title = "Coming Soon", 
  description = "We're working hard to bring you something amazing. Stay tuned!", 
  expectedDate = "February 2025" 
}) => {
  const [email, setEmail] = useState('');
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [waitlistCount, setWaitlistCount] = useState(0);
  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  });

  // Fetch waitlist count on mount
  useEffect(() => {
    const fetchWaitlistCount = async () => {
      const count = await waitlistService.getWaitlistCount();
      setWaitlistCount(count);
    };
    fetchWaitlistCount();
  }, []);

  // Set a target date 30 days from now for the countdown
  useEffect(() => {
    const targetDate = new Date();
    targetDate.setDate(targetDate.getDate() + 30);

    const interval = setInterval(() => {
      const now = new Date();
      const difference = targetDate.getTime() - now.getTime();

      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((difference / (1000 * 60)) % 60);
      const seconds = Math.floor((difference / 1000) % 60);

      setCountdown({ days, hours, minutes, seconds });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const handleSubscribe = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);
  
    try {
      console.log('Submitting email:', email); // Debug log
  
      // Basic email validation
      if (!email || !email.includes('@')) {
        setError('Please enter a valid email address');
        setIsLoading(false);
        return;
      }
  
      const result = await waitlistService.addToWaitlist(email, 'comingSoon');
      console.log('Submission result:', result); // Debug log
  
      if (result.success) {
        setIsSubscribed(true);
        setEmail('');
        // Refresh waitlist count
        const newCount = await waitlistService.getWaitlistCount();
        setWaitlistCount(newCount);
      } else {
        setError(result.message);
      }
    } catch (err) {
      console.error('Submission error:', err); // Debug log
      setError(err.message || 'Failed to join waitlist. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const CountdownBox = ({ value, label }) => (
    <div className="flex flex-col items-center p-4 bg-white rounded-lg shadow-sm">
      <span className="text-3xl font-bold text-blue-600">{value}</span>
      <span className="text-sm text-gray-600">{label}</span>
    </div>
  );

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-50 flex flex-col items-center justify-center p-4">
      <div className="max-w-3xl w-full text-center space-y-8">
        {/* Header */}
        <div className="space-y-4">
          <h1 className="text-4xl md:text-5xl font-bold text-gray-900">
            {title}
          </h1>
          <p className="text-xl text-gray-600">
            {description}
          </p>
        </div>

        {/* Countdown Timer */}
        {/* <div className="grid grid-cols-2 md:grid-cols-4 gap-4 max-w-xl mx-auto">
          <CountdownBox value={countdown.days} label="Days" />
          <CountdownBox value={countdown.hours} label="Hours" />
          <CountdownBox value={countdown.minutes} label="Minutes" />
          <CountdownBox value={countdown.seconds} label="Seconds" />
        </div> */}

        {/* Expected Date */}
        <div className="flex items-center justify-center space-x-2 text-gray-600">
          <Calendar className="w-5 h-5" />
          <span>Expected Launch: {expectedDate}</span>
        </div>

        {/* Waitlist Section */}
        <div className="mt-12 bg-white rounded-xl shadow-lg p-8 max-w-2xl mx-auto">
        {error && (
            <Alert variant="destructive" className="mb-6">
              <AlertCircle className="w-4 h-4" />
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}
        {!isSubscribed ? (
            <div className="space-y-6">
              <div className="space-y-2">
                <h2 className="text-2xl font-bold text-gray-900">Join the Waitlist</h2>
                <p className="text-gray-600">
                  Be among the first to experience this feature when it launches. Early access members will receive:
                </p>
              </div>
              
              <div className="space-y-3">
                <div className="flex items-center text-gray-700">
                  <CheckCircle className="w-5 h-5 text-green-500 mr-2" />
                  <span>Priority access to the feature</span>
                </div>
                <div className="flex items-center text-gray-700">
                  <CheckCircle className="w-5 h-5 text-green-500 mr-2" />
                  <span>Exclusive early-bird benefits</span>
                </div>
                <div className="flex items-center text-gray-700">
                  <CheckCircle className="w-5 h-5 text-green-500 mr-2" />
                  <span>Special launch day perks</span>
                </div>
              </div>

              <form onSubmit={handleSubscribe} className="mt-6">
                <div className="flex flex-col md:flex-row gap-3">
                  <div className="flex-1 relative">
                    <Mail className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Enter your email address"
                      className="w-full pl-10 pr-4 py-3 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                      required
                      disabled={isLoading}
                    />
                  </div>
                  <button
                    type="submit"
                    disabled={isLoading}
                    className="inline-flex items-center justify-center px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors disabled:opacity-50"
                  >
                    {isLoading ? (
                      <>Loading...</>
                    ) : (
                      <>
                        Join Waitlist
                        <ArrowRight className="ml-2 w-4 h-4" />
                      </>
                    )}
                  </button>
                </div>
              </form>

              {waitlistCount > 0 && (
                <div className="flex items-center justify-center space-x-2 text-gray-500 text-sm mt-4">
                  <Users className="w-4 h-4" />
                  <span>Join {waitlistCount.toLocaleString()}+ others on the waitlist</span>
                </div>
              )}
            </div>
          ) : (
            <div className="text-center space-y-4 py-6">
              <div className="flex justify-center">
                <div className="bg-green-100 text-green-600 rounded-full p-3">
                  <CheckCircle className="w-8 h-8" />
                </div>
              </div>
              <h3 className="text-xl font-semibold text-gray-900">You're on the list!</h3>
              <p className="text-gray-600">
                Thank you for joining our waitlist. We'll notify you as soon as we launch!
              </p>
            </div>
          )}
        </div>

        {/* Footer Note */}
        <div className="text-sm text-gray-500 mt-8">
          <p>We'll keep you updated on our progress and notify you when we launch.</p>
        </div>
      </div>
    </div>
  );
};

// Wrapper component that includes the dashboard layout
const ComingSoon = () => {
    return (
      <div className="min-h-screen flex flex-col">
        <Header />
        <div className="flex flex-1">
          <Sidebar />
          <main className="flex-1 bg-gray-100">
            <ComingSoonContent />
          </main>
        </div>
      </div>
    );
  };

export default ComingSoon;