import { loadStripe } from '@stripe/stripe-js';
import React, { useState, useEffect } from 'react';
import { 
  ArrowLeft, 
  Check, 
  CheckCircle2,
  AlertCircle,
  Loader2
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { Alert, AlertDescription } from './components/Alert';
import { useUser } from './UserContext';


// Initialize Stripe with error handling
let stripePromise = null;
const getStripe = async () => {
  if (!stripePromise) {
    const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
    if (!STRIPE_PUBLIC_KEY) {
      throw new Error('Stripe public key is missing from environment variables');
    }
    stripePromise = loadStripe(STRIPE_PUBLIC_KEY);
  }
  return stripePromise;
};


const PricingPage = () => {
  const navigate = useNavigate();
  const { user } = useUser();
  const [billingPeriod, setBillingPeriod] = useState('yearly');
  const [isLoading, setIsLoading] = useState(false);
  const [loadingTier, setLoadingTier] = useState(null);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [error, setError] = useState(null);
  const [isLoadingStatus, setIsLoadingStatus] = useState(true);
  // Add state for Stripe initialization status
  const [isStripeInitialized, setIsStripeInitialized] = useState(false);
  const [stripeError, setStripeError] = useState(null);

  // Initialize Stripe on component mount
  useEffect(() => {
    const initStripe = async () => {
      try {
        await getStripe();
        setIsStripeInitialized(true);
      } catch (error) {
        console.error('Failed to initialize Stripe:', error);
        setStripeError(error.message);
      }
    };

    initStripe();
  }, []);

  const fetchSubscriptionStatus = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/stripe/subscription-status`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      
      if (!response.ok) throw new Error('Failed to fetch subscription status');
      
      const data = await response.json();
      setSubscriptionStatus(data);
    } catch (error) {
      console.error('Error fetching subscription:', error);
      setError('Failed to fetch subscription status');
    }
  };

  const handleSubscribe = async (tier) => {
    try {
      setLoadingTier(tier.name);
      setError(null);
  
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/stripe/create-checkout-session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Accept': 'application/json',
        },
        credentials: 'include',  // Important for CORS with credentials
        body: JSON.stringify({
          planType: tier.name.toLowerCase(),
          billingCycle: billingPeriod,
          email: user.email
        })
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to create checkout session');
      }
  
      const { sessionId } = await response.json();
      const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
      
      const { error } = await stripe.redirectToCheckout({ sessionId });
      if (error) {
        throw error;
      }
    } catch (error) {
      console.error('Subscription error:', error);
      setError(error.message);
    } finally {
      setLoadingTier(null);
    }
  };

  // Show Stripe initialization error if any
  if (stripeError) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <Alert variant="destructive" className="max-w-md">
          <AlertCircle className="h-4 w-4" />
          <AlertDescription>
            Payment system initialization failed. Please try again later.
          </AlertDescription>
        </Alert>
      </div>
    );
  }

  const renderSubscribeButton = (tier) => {
    const isCurrentPlan = subscriptionStatus?.planType === tier.name.toLowerCase();
    const isLoading = loadingTier === tier.name;

    if (isLoading) {
      return (
        <button
          disabled
          className={`w-full py-2 px-4 rounded-lg font-medium ${
            tier.popular ? 'bg-blue-600' : 'bg-gray-900'
          } text-white opacity-75 flex items-center justify-center`}
        >
          <Loader2 className="w-5 h-5 mr-2 animate-spin" />
          Processing...
        </button>
      );
    }

    if (isCurrentPlan) {
      return (
        <button
          disabled
          className="w-full py-2 px-4 rounded-lg font-medium bg-green-600 text-white"
        >
          Current Plan
        </button>
      );
    }

    return (
      <button
        onClick={() => handleSubscribe(tier)}
        disabled={!isStripeInitialized}
        className={`w-full py-2 px-4 rounded-lg font-medium transition-colors ${
          tier.popular
            ? 'bg-blue-600 text-white hover:bg-blue-700'
            : 'bg-gray-900 text-white hover:bg-gray-800'
        } ${!isStripeInitialized ? 'opacity-50 cursor-not-allowed' : ''}`}
      >
        {tier.cta}
      </button>
    );
  };


    const pricingTiers = [
    {
      name: 'Starter',
      description: 'Perfect for individuals and small businesses',
      monthlyPrice: 15,
      yearlyPrice: 144,
      features: [
        //'Up to 3 social accounts',
        '30 scheduled posts per month',
        'Get 10 AI-powered post ideas',
        'Get 30 AI-generated post',
        //'Basic analytics',
        'Content calendar',
        //'Mobile app access'
      ],
      cta: 'Start Free Trial',
      popular: false,
      color: 'bg-white'
    },
    {
      name: 'Professional',
      description: 'Ideal for growing businesses and creators',
      monthlyPrice: 39,
      yearlyPrice: 384,
      features: [
        //'Up to 10 social accounts',
        'Unlimited scheduled posts',
        //'Advanced analytics',
        //'Team collaboration',
        //'Custom report builder',
        'Priority support',
        'Get 30 AI-powered post ideas',
        'Get unlimited AI-generated post'
      ],
      cta: 'Start Free Trial',
      popular: true,
      color: 'bg-blue-50'
    },
    {
      name: 'Business',
      description: 'For larger teams and organizations',
      monthlyPrice: 99,
      yearlyPrice: 948,
      features: [
        //'Up to 25 social accounts',
        'Unlimited scheduled posts',
        'Enterprise analytics',
        'Custom workflows',
        //'API access',
        '24/7 priority support',
        'Advanced AI features',
        //'Custom branding',
        'Dedicated success manager'
      ],
      cta: 'Contact Sales',
      popular: false,
      color: 'bg-white'
    }
  ];

  const allFeatures = {
    'Content Management': {
      'Schedule posts': {
        starter: true,
        professional: true,
        business: true
      },
      'Bulk scheduling': {
        starter: false,
        professional: true,
        business: true
      },
      'Content calendar': {
        starter: true,
        professional: true,
        business: true
      },
      'Media library': {
        starter: '1GB storage',
        professional: '10GB storage',
        business: '100GB storage'
      },
      'AI post suggestions': {
        starter: false,
        professional: '50/month',
        business: 'Unlimited'
      }
    },
    'Analytics': {
      'Basic metrics': {
        starter: true,
        professional: true,
        business: true
      },
      'Advanced reports': {
        starter: false,
        professional: true,
        business: true
      },
      'Custom dashboards': {
        starter: false,
        professional: '3 dashboards',
        business: 'Unlimited'
      },
      'Export reports': {
        starter: false,
        professional: true,
        business: true
      }
    },
    'Team & Workflow': {
      'Team members': {
        starter: '1 user',
        professional: '5 users',
        business: '25 users'
      },
      'Approval workflow': {
        starter: false,
        professional: true,
        business: true
      },
      'Role management': {
        starter: false,
        professional: true,
        business: true
      }
    }
  };


  return (
    <div className="min-h-screen bg-gray-50">
      <nav className="bg-white border-b border-gray-100">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex-shrink-0 flex items-center">
              <span className="text-2xl font-bold text-blue-600">Socioblink</span>
            </div>
            <button 
              onClick={() => navigate('/')}
              className="flex items-center text-gray-600 hover:text-gray-900"
            >
              <ArrowLeft className="w-5 h-5 mr-2" />
              Back to Home
            </button>
          </div>
        </div>
      </nav>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {error && (
          <Alert variant="destructive" className="mb-6">
            <AlertCircle className="h-4 w-4" />
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}

        {/* Header */}
        <div className="text-center mb-12">
        <h1 className="text-4xl font-bold text-gray-900 mb-4">
            Simple, Transparent Pricing
          </h1>
          <p className="text-xl text-gray-600 mb-8">
            Choose the perfect plan for your social media needs
          </p>

          {/* Billing Toggle */}
          <div className="inline-flex items-center bg-gray-100 p-1 rounded-lg">
            <button
              onClick={() => setBillingPeriod('monthly')}
              className={`px-4 py-2 rounded-md text-sm font-medium transition-colors ${
                billingPeriod === 'monthly'
                  ? 'bg-white text-gray-900 shadow-sm'
                  : 'text-gray-600 hover:text-gray-900'
              }`}
            >
              Monthly
            </button>
            <button
              onClick={() => setBillingPeriod('yearly')}
              className={`px-4 py-2 rounded-md text-sm font-medium transition-colors ${
                billingPeriod === 'yearly'
                  ? 'bg-white text-gray-900 shadow-sm'
                  : 'text-gray-600 hover:text-gray-900'
              }`}
            >
              Yearly
              <span className="ml-1 text-xs font-normal text-green-600">
                Save 20%
              </span>
            </button>
          </div>
        </div>

        {/* Pricing Cards */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
          {pricingTiers.map((tier, index) => (
            <div 
              key={index}
              className={`relative ${tier.color} rounded-xl shadow-sm overflow-hidden border border-gray-200`}
            >
              {tier.popular && (
                <div className="absolute top-0 right-0 bg-blue-600 text-white px-4 py-1 text-sm font-medium rounded-bl-lg">
                  Most Popular
                </div>
              )}
              <div className="p-6">
                <h3 className="text-xl font-bold text-gray-900 mb-2">{tier.name}</h3>
                <p className="text-gray-600 mb-6">{tier.description}</p>
                <div className="mb-6">
                  <div className="flex items-baseline">
                    <span className="text-4xl font-bold text-gray-900">
                      ${billingPeriod === 'monthly' ? tier.monthlyPrice : Math.round(tier.yearlyPrice / 12)}
                    </span>
                    <span className="text-gray-600 ml-2">/month</span>
                  </div>
                  {billingPeriod === 'yearly' && (
                    <div className="text-sm text-green-600 mt-1">
                      ${tier.yearlyPrice} billed yearly
                    </div>
                  )}
                </div>
                {renderSubscribeButton(tier)}
                <ul className="space-y-3">
                  {tier.features.map((feature, idx) => (
                    <li key={idx} className="flex items-start">
                      <CheckCircle2 className="w-5 h-5 text-green-500 mr-2 flex-shrink-0 mt-0.5" />
                      <span className="text-gray-600">{feature}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>

        {/* Feature Comparison */}
        <div className="bg-white rounded-xl shadow-sm overflow-hidden">
        <div className="p-6 border-b">
            <h2 className="text-2xl font-bold text-gray-900">Compare Features</h2>
          </div>
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr className="bg-gray-50">
                  <th className="py-4 px-6 text-left text-sm font-medium text-gray-500">Features</th>
                  <th className="py-4 px-6 text-center text-sm font-medium text-gray-500">Starter</th>
                  <th className="py-4 px-6 text-center text-sm font-medium text-gray-500">Professional</th>
                  <th className="py-4 px-6 text-center text-sm font-medium text-gray-500">Business</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(allFeatures).map(([category, features]) => (
                  <React.Fragment key={category}>
                    <tr className="bg-gray-50">
                      <td colSpan={4} className="py-3 px-6 text-sm font-medium text-gray-900">
                        {category}
                      </td>
                    </tr>
                    {Object.entries(features).map(([feature, tiers], index) => (
                      <tr key={feature} className="border-t">
                        <td className="py-4 px-6 text-sm text-gray-600">
                          {feature}
                        </td>
                        {Object.entries(tiers).map(([tier, value], idx) => (
                          <td key={tier} className="py-4 px-6 text-center">
                            {typeof value === 'boolean' ? (
                              value ? (
                                <Check className="w-5 h-5 text-green-500 mx-auto" />
                              ) : (
                                <div className="w-5 h-0.5 bg-gray-200 mx-auto" />
                              )
                            ) : (
                              <span className="text-sm text-gray-600">{value}</span>
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* FAQ Section */}
        <div className="mt-16">
        <h2 className="text-2xl font-bold text-gray-900 text-center mb-8">
            Frequently Asked Questions
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {/* Add FAQs here */}
          </div>
        </div>

        {/* CTA Section */}
        <div className="mt-16 text-center">
        <h2 className="text-2xl font-bold text-gray-900 mb-4">
            Ready to Get Started?
          </h2>
          <p className="text-gray-600 mb-8">
            Start your 14-day free trial today. No credit card required.
          </p>
          <button className="px-8 py-3 bg-blue-600 text-white rounded-lg font-medium hover:bg-blue-700 transition-colors">
            Try Socioblink Free
          </button>
        </div>
      </div>
    </div>
  );
};

export default PricingPage;