// ScheduleDialog.js
import React, { useState } from 'react';
import { 
  X as XIcon, 
  CalendarDays, 
  Clock,
  AlertTriangle
} from 'lucide-react';
import { Alert, AlertDescription } from './Alert';
import { useUsageLimits } from '../useUsageLimits';

const ScheduleDialog = ({ onClose, onSchedule, isLoading }) => {
  const [scheduledDate, setScheduledDate] = useState('');
  const [scheduledTime, setScheduledTime] = useState('');
  const [error, setError] = useState('');
  
  const {
    limits,
    canSchedulePost,
    formatLimitDisplay,
    getNextResetDate,
    incrementUsage
  } = useUsageLimits();

  const validateAndSchedule = async () => {
    if (!scheduledDate || !scheduledTime) {
      setError('Please select both date and time');
      return;
    }

    const scheduledDateTime = new Date(`${scheduledDate}T${scheduledTime}`);
    if (scheduledDateTime <= new Date()) {
      setError('Scheduled time must be in the future');
      return;
    }

    if (!canSchedulePost()) {
      setError('You have reached your scheduled posts limit for this period');
      return;
    }

    try {
      // First try to schedule the post
      await onSchedule(scheduledDate, scheduledTime);
      
      // If scheduling was successful, increment the usage counter
      await incrementUsage('scheduledPosts');
      
      onClose();
    } catch (err) {
      setError(err.message || 'Failed to schedule post');
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-medium">Schedule Post</h3>
          <button 
            onClick={onClose}
            className="p-1 hover:bg-gray-100 rounded-full"
          >
            <XIcon className="w-5 h-5" />
          </button>
        </div>

        {/* Usage Limits Display */}
        {limits && (
          <div className="mb-4 p-4 bg-gray-50 rounded-lg">
            <div className="text-sm text-gray-600">
              Scheduled Posts: {formatLimitDisplay(limits.scheduledPosts.total, limits.scheduledPosts.used)}
              {limits.scheduledPosts.resetDate && (
                <span className="block text-xs text-gray-500">
                  Resets on {getNextResetDate('scheduledPosts')}
                </span>
              )}
            </div>
          </div>
        )}

        {error && (
          <Alert variant="destructive" className="mb-4">
            <AlertTriangle className="h-4 w-4" />
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}

        {!canSchedulePost() && (
          <Alert variant="destructive" className="mb-4">
            <AlertTriangle className="h-4 w-4" />
            <AlertDescription>
              You've reached your scheduled posts limit. 
              {limits?.scheduledPosts.resetDate && (
                <> Your limit will reset on {getNextResetDate('scheduledPosts')}.</>
              )}
              {' '}
              <a href="/upgrade" className="text-blue-600 hover:underline">
                Upgrade your plan
              </a> for more scheduled posts.
            </AlertDescription>
          </Alert>
        )}
        
        <div className="space-y-4">
          <div>
            <label htmlFor="date" className="block text-sm font-medium text-gray-700 mb-1">
              Date
            </label>
            <div className="relative">
              <input
                type="date"
                id="date"
                className="w-full pl-10 pr-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={scheduledDate}
                onChange={(e) => {
                  setScheduledDate(e.target.value);
                  setError('');
                }}
                min={new Date().toISOString().split('T')[0]}
              />
              <CalendarDays className="absolute left-3 top-1/2 -translate-y-1/2 w-4 h-4 text-gray-400" />
            </div>
          </div>
          
          <div>
            <label htmlFor="time" className="block text-sm font-medium text-gray-700 mb-1">
              Time
            </label>
            <div className="relative">
              <input
                type="time"
                id="time"
                className="w-full pl-10 pr-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={scheduledTime}
                onChange={(e) => {
                  setScheduledTime(e.target.value);
                  setError('');
                }}
              />
              <Clock className="absolute left-3 top-1/2 -translate-y-1/2 w-4 h-4 text-gray-400" />
            </div>
          </div>
        </div>

        <div className="flex justify-end space-x-3 mt-6">
          <button
            onClick={onClose}
            disabled={isLoading}
            className="px-4 py-2 text-gray-700 bg-white border rounded-lg hover:bg-gray-50"
          >
            Cancel
          </button>
          <button
            onClick={validateAndSchedule}
            disabled={isLoading || !canSchedulePost()}
            className="px-4 py-2 text-white bg-blue-500 rounded-lg hover:bg-blue-600 disabled:opacity-50 disabled:cursor-not-allowed flex items-center"
          >
            {isLoading ? (
              <>
                <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                </svg>
                Scheduling...
              </>
            ) : (
              'Schedule Post'
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ScheduleDialog;