import React, { useState } from 'react';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import CreateAIPost from './components/CreateAIPost';
import CreatePost from './components/CreatePost';
import CreatePostModal from './components/CreatePostModal';

const FullWidthPostCreator = ({ 
  onClose, 
  getConnectedAccounts, 
  initialContent = '' 
}) => {
  const [showModal, setShowModal] = useState(true);
  const [creationType, setCreationType] = useState(null);
  const [postContent, setPostContent] = useState(initialContent);
  const [showSidebar, setShowSidebar] = useState(true);

  const handleCreateWithAI = () => {
    setCreationType('ai');
    setShowModal(false);
    setShowSidebar(false); // Hide sidebar
  };

  const handleCreateManually = () => {
    setCreationType('manual');
    setShowModal(false);
    setShowSidebar(false); // Hide sidebar
  };

  const handleSelectAIPost = (content) => {
    setCreationType('manual');
    setPostContent(content);
  };

  const handleClose = () => {
    setShowModal(true);
    setCreationType(null);
    setShowSidebar(true); // Show sidebar again
    onClose();
  };

  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      <div className="flex flex-1">
        {/* Sidebar with conditional rendering */}
        {showSidebar && <Sidebar />}

        {/* Main content with dynamic width */}
        <main className={`flex-1 bg-gray-100 transition-all duration-300 ${
          !showSidebar ? 'ml-0' : ''
        }`}>
          {showModal ? (
            <CreatePostModal
              isOpen={true}
              onClose={handleClose}
              onCreateWithAI={handleCreateWithAI}
              onCreateManually={handleCreateManually}
            />
          ) : creationType === 'ai' ? (
            <CreateAIPost
              onClose={handleClose}
              onSelectPost={handleSelectAIPost}
            />
          ) : (
            <CreatePost
              onClose={handleClose}
              connectedAccounts={getConnectedAccounts()}
              mode={creationType}
              initialContent={postContent}
              className="max-w-none" // Remove max-width constraint
            />
          )}
        </main>
      </div>
    </div>
  );
};

export default FullWidthPostCreator;