import React from 'react';
import { X as XIcon, Video, FileText } from 'lucide-react';
import AIPostGeneratorModal from './AIPostGeneratorModal';
import { useUsageLimits } from '../useUsageLimits';
import { Alert, AlertDescription } from './Alert';

const PostInput = ({ 
  content, 
  onContentChange, 
  selectedImage,
  selectedVideo,
  selectedDocument,
  onImageChange,
  onVideoChange,
  onDocumentChange,
  firstComment,
  onFirstCommentChange,
  showAIGenerator,
  onShowAIGenerator
}) => {
  const { incrementUsage } = useUsageLimits();

  const renderMediaPreview = () => {
    if (!selectedImage && !selectedVideo && !selectedDocument) return null;

    if (selectedDocument) {
      return (
        <div className="relative group mt-4 border rounded-lg p-4">
          <div className="flex items-center">
            <FileText className="w-8 h-8 text-blue-500 mr-3" />
            <div className="flex-1">
              <p className="text-sm font-medium text-gray-900">
                {selectedDocument.name || 'Document'}
              </p>
              <p className="text-xs text-gray-500">
                {selectedDocument.type} • Click to view
              </p>
            </div>
            <button
              onClick={() => onDocumentChange(null)}
              className="p-2 text-gray-500 hover:text-gray-700 rounded-full hover:bg-gray-100"
              title="Remove document"
            >
              <XIcon className="w-4 h-4" />
            </button>
          </div>
        </div>
      );
    }


    if (selectedVideo) {
      return (
        <div className="relative group mt-4">
          <video 
            src={selectedVideo} 
            controls
            className="max-w-full h-auto rounded-lg"
          />
          <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
            <button
              onClick={() => onVideoChange(null)}
              className="p-2 bg-white rounded-full hover:bg-gray-100"
              title="Remove video"
            >
              <XIcon className="w-4 h-4" />
            </button>
          </div>
        </div>
      );
    }

    if (selectedImage) {
      return (
        <div className="relative group mt-4">
          <img 
            src={selectedImage} 
            alt="Upload preview" 
            className="max-w-full h-auto rounded-lg"
          />
          <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
            <button
              onClick={() => onImageChange(null)}
              className="p-2 bg-white rounded-full hover:bg-gray-100"
              title="Remove media"
            >
              <XIcon className="w-4 h-4" />
            </button>
          </div>
        </div>
      );
    }

    return (
      <div className="relative group mt-4">
        <img 
          src={selectedImage} 
          alt="Upload preview" 
          className="max-w-full h-auto rounded-lg"
        />
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
          <button
            onClick={() => onImageChange(null)}
            className="p-2 bg-white rounded-full hover:bg-gray-100"
            title="Remove media"
          >
            <XIcon className="w-4 h-4" />
          </button>
        </div>
      </div>
    );
  };

  const handleGeneratedPost = async (post) => {
    try {
      // Increment AI generation usage when a post is selected
      const success = await incrementUsage('aiGeneration');
      if (success) {
        onContentChange(post);
      }
    } catch (error) {
      console.error('Error incrementing AI usage:', error);
    }
    // await incrementUsage('aiGeneration');
    // onContentChange(post);
  };

  return (
    <div className="mb-6">
      <div className="min-h-[200px] border rounded-lg p-4">
        <textarea
          placeholder="What would you like to share?"
          value={content}
          onChange={(e) => onContentChange(e.target.value)}
          className="w-full h-full min-h-[120px] resize-none focus:outline-none"
        />
        
        {renderMediaPreview()}

        <AIPostGeneratorModal
          isOpen={showAIGenerator}
          onClose={() => onShowAIGenerator(false)}
          onSelectPost={handleGeneratedPost}
        />
      </div>
    </div>
  );
};

export default PostInput;