// hooks/useUsageLimits.js
import { useState, useEffect } from 'react';
import { useUser } from './UserContext';

export const useUsageLimits = () => {
  const { user } = useUser();
  const [limits, setLimits] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchLimits = async () => {
      try {
        // Get limits from user object if available
        if (user?.usageLimits) {
            setLimits(user.usageLimits);
            return;
        }
        
        // Otherwise fetch from API
        const token = localStorage.getItem('token');
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user/limits`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) throw new Error('Failed to fetch usage limits');
        const data = await response.json();
        setLimits(data.limits);
      } catch (err) {
        console.error('Error fetching limits:', err);
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchLimits();
  }, [user]);

  const formatLimitDisplay = (limit, used) => {
    if (limit === -1) return 'Unlimited';
    return `${used} / ${limit}`;
  };

  const canUseFeature = (feature) => {
    if (!limits?.[feature]) return false;
    const { limit, used } = limits[feature];
    return limit === -1 || used < limit;
  };

  // Specific feature checks
  // const canPublishPost = () => canUseFeature('publishedPosts');
  // const canSchedulePost = () => canUseFeature('scheduledPosts');
  // const canUseAiGeneration = () => canUseFeature('aiGeneration');
  const canAddSocialAccount = () => canUseFeature('socialAccounts');

  const canUseAiGeneration = () => {
    if (!limits) return false;
    const { aiGeneration } = limits;
    return aiGeneration.total === -1 || aiGeneration.used < aiGeneration.total;
  };

  const canSchedulePost = () => {
    if (!limits) return false;
    const { scheduledPosts } = limits;
    return scheduledPosts.total === -1 || scheduledPosts.used < scheduledPosts.total;
  };

  // Add new check for publish now count
  const canPublishPost = () => {
      if (!limits) return false;
      const { publishedPosts } = limits;
      return publishedPosts.total === -1 || publishedPosts.used < publishedPosts.total;
  };

  const getNextResetDate = (type) => {
    if (!limits || !limits[type]?.resetDate) return null;
    return new Date(limits[type].resetDate).toLocaleDateString();
  };

  const getRemainingLimit = (feature) => {
    if (!limits?.[feature]) return 0;
    const { limit, used } = limits[feature];
    if (limit === -1) return Infinity;
    return Math.max(0, limit - used);
  };

    // Get usage percentage for a feature
    const getUsagePercentage = (feature) => {
      if (!limits?.[feature]) return 0;
      const { limit, used } = limits[feature];
      if (limit === -1) return (used / 1000) * 100; // Arbitrary scale for unlimited
      return (used / limit) * 100;
    };

  const incrementUsage = async (feature) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user/limits/increment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ feature })
      });

      if (!response.ok) throw new Error('Failed to increment usage');
      const data = await response.json();

      // Update local state
      setLimits(prev => ({
        ...prev,
        [feature]: {
          ...prev[feature],
          used: data.newCount
        }
      }));

      return true;
    } catch (err) {
      console.error('Error incrementing usage:', err);
      return false;
    }
  };

  return {
    limits,
    isLoading,
    error,
    canUseAiGeneration,
    canSchedulePost,
    canPublishPost,
    canAddSocialAccount,
    formatLimitDisplay,
    getNextResetDate,
    getRemainingLimit,
    incrementUsage,
    getUsagePercentage
  };
};