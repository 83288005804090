import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Header from './components/Header';
import SocialMediaTabs from './components/SocialMediaTabs';
import PostList from './components/PostList';
import { PLATFORMS } from './Constants';
import { useUser } from './UserContext';
import { Alert, AlertDescription } from './components/Alert';
import FullWidthPostCreator from './FullWidthPostCreator';

const Dashboard = () => {
  const { user } = useUser();
  const [connectedChannels, setConnectedChannels] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [postType, setPostType] = useState('scheduled');
  const [showCreatePost, setShowCreatePost] = useState(false);
  const navigate = useNavigate();

  // Function to get connected channels from user's social accounts
  const getConnectedChannels = () => {
    const channels = [];
    if (user?.socialAccounts?.linkedin?.isConnected) {
      channels.push('LinkedIn');
    }
    if (user?.socialAccounts?.instagram?.isConnected) {
      channels.push('Instagram');
    }
    if (user?.socialAccounts?.tiktok?.isConnected) {
      channels.push('TikTok');
    }
    return channels;
  };

  const getConnectedAccountsForCreatePost = () => {
    return Object.entries(PLATFORMS)
      .filter(([key]) => user?.socialAccounts?.[key.toLowerCase()]?.isConnected)
      .map(([, value]) => value);
  };

  useEffect(() => {
    const fetchConnectedChannels = async () => {
      try {
        setIsLoading(true);
        setError(null);
        const channels = getConnectedChannels();
        
        if (channels.length === 0) {
          setError('No connected social media accounts found. Please connect your accounts in the Channels section.');
        }
        
        setConnectedChannels(channels);
        
        if (channels.length > 0 && !selectedChannel) {
          setSelectedChannel(channels[0]);
        } else if (channels.length === 0) {
          setSelectedChannel(null);
        }
      } catch (error) {
        console.error('Error fetching channels:', error);
        setError('Failed to fetch connected channels. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchConnectedChannels();
  }, [user]);

  useEffect(() => {
    if (selectedChannel) {
      setPostType('scheduled');
      fetchPosts(selectedChannel, 'scheduled');
    }
  }, [selectedChannel]);

  useEffect(() => {
    if (selectedChannel && postType) {
      fetchPosts(selectedChannel, postType);
    }
  }, [postType]);

  const fetchPosts = async (channel, type) => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('No authentication token found');

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/posts?platform=${channel.toLowerCase()}&status=${type}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );

      if (!response.ok) throw new Error('Failed to fetch posts');

      const data = await response.json();
      setPosts(data.posts || []);
    } catch (error) {
      console.error('Error fetching posts:', error);
      setError('Failed to fetch posts. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  // Show the full-width post creator when creating a post
  if (showCreatePost) {
    return (
      <FullWidthPostCreator
        onClose={() => setShowCreatePost(false)}
        getConnectedAccounts={getConnectedAccountsForCreatePost}
      />
    );
  }

  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      <div className="flex flex-1">
        <Sidebar />
        <main className="flex-1 bg-gray-100">
          <div className="p-6">
            <h3 className="text-2xl font-medium text-gray-800 mb-6">Home</h3>
            
            {error && (
              <Alert variant="destructive" className="mb-6">
                <AlertDescription>{error}</AlertDescription>
              </Alert>
            )}

            {connectedChannels.length === 0 ? (
              <div className="text-center py-12">
                <h3 className="text-lg font-medium text-gray-900 mb-2">
                  No Connected Accounts
                </h3>
                <p className="text-gray-500 mb-6">
                  Connect your social media accounts to start managing your content.
                </p>
                <button
                  onClick={() => navigate('/channels')}
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
                >
                  Connect Accounts
                </button>
              </div>
            ) : (
              <>
                <div className="mb-6">
                  <SocialMediaTabs 
                    channels={connectedChannels} 
                    selectedChannel={selectedChannel} 
                    onSelectChannel={channel => setSelectedChannel(channel)} 
                  />
                </div>
                <PostList 
                  posts={posts} 
                  postType={postType}
                  onPostTypeChange={setPostType}
                  onCreatePost={() => setShowCreatePost(true)}
                  isLoading={isLoading}
                />
              </>
            )}
          </div>
        </main>
      </div>
    </div>
  );
};

export default Dashboard;