import React, { useState, useEffect } from 'react';
import { 
  Sparkles, 
  Loader2, 
  Settings, 
  AlertCircle,
  Wand2,
  Send,
  Pencil,
  ArrowRight,
  HashIcon,
  BarChart,
  Globe
} from 'lucide-react';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import { Alert, AlertDescription } from './components/Alert';
import { useUser } from './UserContext';
import { useUsageLimits } from './useUsageLimits';

const AISuggest = () => {
  const [topics, setTopics] = useState([]);
  const [lastGeneratedAt, setLastGeneratedAt] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [showPreferences, setShowPreferences] = useState(false);
  const [preferences, setPreferences] = useState({
    industry: '',
    targetAudience: '',
    interests: []
  });
  const [newInterest, setNewInterest] = useState('');
  const { user } = useUser();
  const { canUseAiGeneration, formatLimitDisplay, getNextResetDate, incrementUsage, limits } = useUsageLimits();

  useEffect(() => {
    fetchPreferences();
  }, []);

    // Add new useEffect to fetch last generated topics
    useEffect(() => {
        const fetchLastTopics = async () => {
          try {
            const token = localStorage.getItem('token');
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/ai-suggest/last-topics`, {
              headers: {
                'Authorization': `Bearer ${token}`
              }
            });
    
            if (!response.ok) throw new Error('Failed to fetch last topics');
            
            const data = await response.json();
            if (data.topics && data.topics.length > 0) {
              setTopics(data.topics);
              setLastGeneratedAt(new Date(data.generatedAt));
            }
          } catch (error) {
            console.error('Error fetching last topics:', error);
          }
        };
    
        fetchLastTopics();
      }, []);
    

  const fetchPreferences = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/ai-suggest/preferences`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (!response.ok) throw new Error('Failed to fetch preferences');
      const data = await response.json();
      
      if (data.preferences) {
        setPreferences(data.preferences);
        if (!data.preferences.industry || !data.preferences.targetAudience) {
          setShowPreferences(true);
        }
      }
    } catch (error) {
      console.error('Error fetching preferences:', error);
    }
  };

  // Update the generate topics handler
  const handleGenerateTopics = async () => {
    if (!canUseAiGeneration()) {
      setError('You have reached your AI generation limit');
      return;
    }
    
    setIsLoading(true);
    setError('');

    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/ai-suggest/suggest-topics`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(preferences)
      });

      if (!response.ok) {
        const data = await response.json();
        if (data.hasStoredPreferences === false) {
          setShowPreferences(true);
          throw new Error('Please set your content preferences first');
        }
        throw new Error('Failed to generate topics');
      }

      const data = await response.json();
      setTopics(data || []);
      setLastGeneratedAt(new Date());
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

    // Add last generated time display
    const renderLastGeneratedTime = () => {
        if (!lastGeneratedAt) return null;
    
        return (
          <div className="text-sm text-gray-500 mb-6">
            Last generated: {new Date(lastGeneratedAt).toLocaleString()}
          </div>
        );
      };
    

  const handleSavePreferences = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/ai-suggest/preferences`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(preferences)
      });

      if (!response.ok) throw new Error('Failed to save preferences');
      setShowPreferences(false);
      handleGenerateTopics();
    } catch (error) {
      setError(error.message);
    }
  };

  const handleAddInterest = () => {
    if (newInterest && !preferences.interests.includes(newInterest)) {
      setPreferences(prev => ({
        ...prev,
        interests: [...(prev.interests || []), newInterest]
      }));
      setNewInterest('');
    }
  };

  const handleRemoveInterest = (interest) => {
    setPreferences(prev => ({
      ...prev,
      interests: prev.interests.filter(i => i !== interest)
    }));
  };

  const handleUseTopic = (topic) => {
    // Store the selected topic in localStorage for the create post component
    localStorage.setItem('selectedTopic', JSON.stringify(topic));
    // Navigate to create post page
    window.location.href = '/dashboard?action=create-post';
  };

  const TopicCard = ({ topic }) => (
    <div className="bg-white rounded-lg shadow-sm p-6 hover:shadow-md transition-shadow">
      <div className="flex justify-between items-start mb-4">
        <h3 className="text-lg font-semibold text-gray-900">{topic.title}</h3>
        <span className={`px-3 py-1 rounded-full text-sm ${
          topic.estimatedEngagement === 'high' ? 'bg-green-100 text-green-700' :
          topic.estimatedEngagement === 'medium' ? 'bg-yellow-100 text-yellow-700' :
          'bg-gray-100 text-gray-700'
        }`}>
          {topic.estimatedEngagement} engagement
        </span>
      </div>

      <div className="mb-4">
        <div className="text-sm text-blue-600 font-medium mb-2">Viral Hook:</div>
        <p className="text-gray-700">{topic.hook}</p>
      </div>

      <div className="mb-4">
        <div className="text-sm text-blue-600 font-medium mb-2">Key Points:</div>
        <ul className="list-disc list-inside text-gray-700 space-y-1">
          {topic.keyPoints.map((point, index) => (
            <li key={index}>{point}</li>
          ))}
        </ul>
      </div>

      <div className="flex flex-wrap gap-2 mb-4">
        {topic.hashtags.map((tag, index) => (
          <span key={index} className="px-2 py-1 bg-blue-50 text-blue-600 rounded-md text-sm">
            #{tag}
          </span>
        ))}
      </div>

      <div className="flex items-center justify-between pt-4 border-t">
        <div className="flex items-center space-x-2 text-sm text-gray-500">
          <Globe className="w-4 h-4" />
          <span>Best for: {topic.bestPlatforms.join(', ')}</span>
        </div>
        {/* <button
          onClick={() => handleUseTopic(topic)}
          className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
        >
          <Pencil className="w-4 h-4 mr-2" />
          Use This Topic
        </button> */}
      </div>
    </div>
  );

  const PreferencesModal = ({ preferences: initialPreferences, onClose, onSave }) => {
    // Local state for form management
    const [formData, setFormData] = useState({
      industry: initialPreferences.industry || '',
      targetAudience: initialPreferences.targetAudience || '',
      interests: [...(initialPreferences.interests || [])]
    });
    const [newInterest, setNewInterest] = useState('');
  
    // Handle form input changes
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    };
  
    // Handle adding new interest
    const handleAddInterest = (e) => {
      e.preventDefault();
      if (newInterest && !formData.interests.includes(newInterest)) {
        setFormData(prev => ({
          ...prev,
          interests: [...prev.interests, newInterest]
        }));
        setNewInterest('');
      }
    };
  
    // Handle removing interest
    const handleRemoveInterest = (interest) => {
      setFormData(prev => ({
        ...prev,
        interests: prev.interests.filter(i => i !== interest)
      }));
    };
  
    // Handle form submission
    const handleSubmit = () => {
      onSave(formData);
    };
  
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
        <div className="bg-white rounded-lg p-6 max-w-xl w-full mx-4">
          <h2 className="text-xl font-semibold mb-4">Content Preferences</h2>
          
          <form onSubmit={(e) => e.preventDefault()} className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Industry</label>
              <input
                type="text"
                name="industry"
                value={formData.industry}
                onChange={handleInputChange}
                className="w-full p-2 border rounded-lg"
                placeholder="e.g., Technology, Healthcare, Finance"
              />
            </div>
  
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Target Audience</label>
              <input
                type="text"
                name="targetAudience"
                value={formData.targetAudience}
                onChange={handleInputChange}
                className="w-full p-2 border rounded-lg"
                placeholder="e.g., B2B Professionals, Small Business Owners"
              />
            </div>
  
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Interests</label>
              <div className="flex gap-2 mb-2">
                <input
                  type="text"
                  value={newInterest}
                  onChange={(e) => setNewInterest(e.target.value)}
                  className="flex-1 p-2 border rounded-lg"
                  placeholder="Add an interest"
                />
                <button
                  type="button"
                  onClick={handleAddInterest}
                  className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                >
                  Add
                </button>
              </div>
              <div className="flex flex-wrap gap-2">
                {formData.interests.map((interest, index) => (
                  <span
                    key={index}
                    className="px-3 py-1 bg-blue-50 text-blue-600 rounded-full flex items-center"
                  >
                    {interest}
                    <button
                      type="button"
                      onClick={() => handleRemoveInterest(interest)}
                      className="ml-2 text-blue-400 hover:text-blue-600"
                    >
                      ×
                    </button>
                  </span>
                ))}
              </div>
            </div>
  
            <div className="flex justify-end space-x-3 mt-6">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 text-gray-700 bg-white border rounded-lg hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={handleSubmit}
                className="px-4 py-2 text-white bg-blue-600 rounded-lg hover:bg-blue-700"
              >
                Save & Generate
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  };

  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      <div className="flex flex-1">
        <Sidebar />
        <main className="flex-1 bg-gray-50 p-6">
          <div className="max-w-7xl mx-auto">
            <div className="flex justify-between items-center mb-6">
              <div>
                <h1 className="text-2xl font-semibold text-gray-900">AI Topic Suggestions</h1>
                <p className="text-gray-600">Get AI-powered content ideas tailored to your audience</p>
              </div>
              <div className="flex items-center space-x-4">
                <button
                  onClick={() => setShowPreferences(true)}
                  className="flex items-center px-4 py-2 text-gray-700 bg-white border rounded-lg hover:bg-gray-50"
                >
                  <Settings className="w-4 h-4 mr-2" />
                  Preferences
                </button>
                <button
                  onClick={handleGenerateTopics}
                  disabled={isLoading || !canUseAiGeneration()}
                  className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {isLoading ? (
                    <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                  ) : (
                    <Wand2 className="w-4 h-4 mr-2" />
                  )}
                  Generate Topics
                </button>
              </div>
            </div>

            {/* Display usage limits */}
            {limits && (
              <div className="mb-6 p-4 bg-white rounded-lg shadow-sm">
                <div className="text-sm text-gray-600">
                  AI Generations: {formatLimitDisplay(limits.aiGeneration.total, limits.aiGeneration.used)}
                  {limits.aiGeneration.resetDate && (
                    <span className="ml-2 text-gray-500">
                      Resets on {getNextResetDate('aiGeneration')}
                    </span>
                  )}
                </div>
              </div>
            )}

            {renderLastGeneratedTime()}

            {error && (
              <Alert variant="destructive" className="mb-6">
                <AlertCircle className="h-4 w-4" />
                <AlertDescription>{error}</AlertDescription>
              </Alert>
            )}

            {topics.length > 0 ? (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {topics.map((topic, index) => (
                  <TopicCard key={index} topic={topic} />
                ))}
              </div>
            ) : (
              <div className="text-center py-12 bg-white rounded-lg shadow-sm">
                <Sparkles className="w-12 h-12 text-blue-600 mx-auto mb-4" />
                <h3 className="text-lg font-medium text-gray-900 mb-2">
                  No topics generated yet
                </h3>
                <p className="text-gray-500 mb-6 max-w-sm mx-auto">
                  Click the generate button to get AI-powered content suggestions tailored to your audience
                </p>
              </div>
            )}
          </div>
        </main>
      </div>

      {showPreferences && (
  <PreferencesModal
    preferences={preferences}
    onClose={() => setShowPreferences(false)}
    onSave={async (newPreferences) => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/ai-suggest/preferences`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify(newPreferences)
        });

        if (!response.ok) throw new Error('Failed to save preferences');
        
        // Update local state with new preferences
        setPreferences(newPreferences);
        setShowPreferences(false);
        
        // Generate new topics with updated preferences
        handleGenerateTopics();
      } catch (error) {
        setError(error.message);
      }
    }}
  />
)}
    </div>
  );
};

export default AISuggest;