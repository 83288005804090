import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { UserProvider } from './UserContext';
import AuthUI from './AuthUI';
import Dashboard from './Dashboard';
import HomePage from './HomePage';
import Channels from './Channels';
import Calendar from './Calendar';
import ComingSoon from './ComingSoon';
import PrivacyPolicy from './PrivacyPolicy';
import BlogPage from './BlogPage';
import CareersPage from './CareersPage';
import ContactPage from './ContactPage';
import FeaturesPage from './FeaturesPage';
import AboutPage from './AboutPage';
import HelpCenter from './HelpCenter';
import PricingPage from './PricingPage';
import AISuggest from './AISuggest';
import SubscriptionSuccess from './SubscriptionSuccess';
import { PublicOnlyRoute, PrivateRoute } from './ProtectedRoute';


function App() {
  return (
    <UserProvider>
      <Router>
        <Routes>
          {/* Public routes that redirect to dashboard if logged in */}
          <Route path="/" element={
            <PublicOnlyRoute>
              <HomePage />
            </PublicOnlyRoute>
          } />
          <Route path="/auth" element={
            <PublicOnlyRoute>
              <AuthUI />
            </PublicOnlyRoute>
          } />
          
          {/* Protected routes that require authentication */}
          <Route path="/dashboard" element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          } />
          <Route path="/calendar" element={
            <PrivateRoute>
              <Calendar />
            </PrivateRoute>
          } />
          <Route path="/channels" element={
            <PrivateRoute>
              <Channels />
            </PrivateRoute>
          } />
          <Route path="/analytics" element={
            <PrivateRoute>
              <ComingSoon />
            </PrivateRoute>
          } />
          <Route path="/ai-suggests" element={
            <PrivateRoute>
              <AISuggest />
            </PrivateRoute>
          } />
          <Route path="/members" element={
            <PrivateRoute>
              <ComingSoon />
            </PrivateRoute>
          } />
          <Route path="/subscription/success" element={
            <PrivateRoute>
              <SubscriptionSuccess />
            </PrivateRoute>
          } />
          
          {/* Public routes that are always accessible */}
          <Route path="/upgrade" element={<PricingPage />} />
          <Route path="/pricing" element={<PricingPage />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/careers" element={<CareersPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/features" element={<FeaturesPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/help" element={<HelpCenter />} />
        </Routes>
      </Router>
    </UserProvider>
  );
}

export default App;