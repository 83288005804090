// ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';

// Protects routes that should only be accessible when logged out
export const PublicOnlyRoute = ({ children }) => {
  const token = localStorage.getItem('token');
  
  if (token) {
    // If user is logged in, redirect to dashboard
    return <Navigate to="/dashboard" replace />;
  }

  return children;
};

// Protects routes that require authentication
export const PrivateRoute = ({ children }) => {
  const token = localStorage.getItem('token');
  
  if (!token) {
    // If user is not logged in, redirect to auth page
    return <Navigate to="/auth" replace />;
  }

  return children;
};