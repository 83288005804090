import React from 'react';
import { 
  PLATFORM_LABELS, 
  PLATFORM_ICONS, 
  PLATFORM_COLORS,
  CONNECTION_STATUS 
} from '../Constants';
import { Wand2 } from 'lucide-react';

const AccountSelector = ({ 
  accounts, 
  selectedAccounts, 
  onAccountToggle,
  showAIGenerator,
  onShowAIGenerator,
  className = '',
  content = '' // Add content prop to check if there's text to improve
}) => {
  return (
    <div className={`mb-6 ${className}`}>
      <div className="flex justify-between items-end">
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700">
            Post to
          </label>
          <div className="flex flex-wrap gap-3">
            {accounts.map(({ id, isConnected }) => {
              const Icon = PLATFORM_ICONS[id];
              const colors = PLATFORM_COLORS[id];
              
              return (
                <button
                  key={id}
                  onClick={() => onAccountToggle(id)}
                  disabled={!isConnected}
                  className={`
                    flex items-center px-4 py-2 rounded-lg border transition-colors
                    ${selectedAccounts.includes(id) 
                      ? `${colors.bg} text-white`
                      : 'bg-white border-gray-200 hover:bg-gray-50'}
                    ${!isConnected && 'opacity-50 cursor-not-allowed'}
                  `}
                >
                  <Icon className={`w-5 h-5 mr-2 ${selectedAccounts.includes(id) ? 'text-white' : colors.text}`} />
                  <span>{PLATFORM_LABELS[id]}</span>
                  {!isConnected && (
                    <span className="ml-2 text-xs text-gray-500">
                      ({CONNECTION_STATUS.NOT_CONNECTED})
                    </span>
                  )}
                </button>
              );
            })}
          </div>
        </div>

        <button
          onClick={onShowAIGenerator}
          disabled={!content.trim()} // Disable if no content to improve
          className={`flex items-center px-4 py-2 text-blue-600 hover:bg-blue-50 rounded-lg transition-colors
            ${!content.trim() && 'opacity-50 cursor-not-allowed'}`}
        >
          <Wand2 className="w-4 h-4 mr-2" />
          Improve writing with AI
        </button>
      </div>
    </div>
  );
};

export default AccountSelector;